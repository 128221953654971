import { useMemo } from 'react'
import { type Control, useController } from 'react-hook-form'

import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  type OutlinedInputProps,
  type SxProps,
  Typography
} from '@mui/material'
import { getInputColor } from '@core/components/ui/Inputs/helpers'
import { ALLOWED_KEYS, ALPHANUMERIC, NUMERIC, preventDefault } from '@lib/utils'

type InputState = 'success' | 'error' | 'normal' | 'warning' | 'disabled'

type Props = Partial<OutlinedInputProps> & {
  className?: string
  name: string
  control: Control<any>
  label: string
  disabled?: boolean

  borderColor?: string
  disableBorder?: boolean
  stateInput?: InputState
  successCondition?: boolean
  successMessage?: string
  sxInput?: SxProps
  warningCondition?: boolean
  warningMessage?: string
}
export default function DocumentNumber({
  className,
  name,
  control,
  label,
  disabled,

  successCondition,
  disableBorder = false,
  sxInput,
  stateInput,
  borderColor,
  size,
  warningCondition = false
}: Props) {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const inputState = useMemo((): InputState => {
    if (disabled) return 'disabled'
    if (stateInput) return stateInput
    if (error) return 'error'
    if (successCondition) return 'success'
    if (warningCondition) return 'warning'

    return 'normal'
  }, [warningCondition, successCondition, error, stateInput, disabled])

  const color = useMemo(() => getInputColor(inputState), [inputState])

  const isError = error !== undefined

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // Skip key is a allowed key
    if (ALLOWED_KEYS.includes(e.key) || e.ctrlKey) {
      return
    }

    // Only Number values are allowed
    if (field.value.validation === NUMERIC && !e.key.match(/^[0-9]*$/)) {
      e.preventDefault()

      return
    }

    // Only Alphanumeric values are allowed
    if (field.value.validation === ALPHANUMERIC && !e.key.match(/^[a-zA-Z0-9]*$/)) {
      e.preventDefault()
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    const { validation, maxLength } = field.value || {}

    // NUMERIC validation
    if (validation === NUMERIC) {
      // Only Number values are allowed
      if (value.match(/^[0-9]*$/) && value.length <= maxLength) {
        field.onChange({
          ...field.value,
          value
        })
      }
    }

    // ALPHANUMERIC validation
    if (validation === ALPHANUMERIC) {
      // Only alphanumeric values are allowed
      if (value.match(/^[a-zA-Z0-9]*$/) && value.length <= maxLength) {
        field.onChange({
          ...field.value,
          value: value.toUpperCase()
        })
      }
    }
  }

  return (
    <FormControl className={className} variant='outlined' fullWidth>
      <Typography
        component='label'
        htmlFor='document-number'
        sx={{ color: 'var(--color-text-body-b2)' }}
        variant='body3'
        mb={0.5}
      >
        {label}
      </Typography>
      <OutlinedInput
        {...field}
        type='text'
        id='document-number'
        value={field.value?.value ?? ''}
        aria-describedby='document-number-helper-text'
        error={isError}
        size={size}
        disabled={disabled}
        autoComplete='off'
        placeholder={label}
        fullWidth
        inputProps={
          field.value?.validation === NUMERIC ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}
        }
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        onCopy={preventDefault}
        onPaste={preventDefault}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: disableBorder ? 'none' : undefined,
            borderColor: borderColor ?? color.normal
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.normal
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.hover
            }
          },
          ...sxInput
        }}
      />
      <FormHelperText id='document-number-helper-text' error={isError}>
        {error?.message}
      </FormHelperText>
    </FormControl>
  )
}
