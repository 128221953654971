import { type IconProps } from '@core/types/components'

export const CalendarOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 0C8.05228 0 8.5 0.447715 8.5 1V2H16.5V1C16.5 0.447715 16.9477 0 17.5 0C18.0523 0 18.5 0.447715 18.5 1V2H19.5C21.7091 2 23.5 3.79086 23.5 6V19C23.5 21.2091 21.7091 23 19.5 23H5.5C3.29086 23 1.5 21.2091 1.5 19V6C1.5 3.79086 3.29086 2 5.5 2H6.5V1C6.5 0.447715 6.94772 0 7.5 0ZM5.5 4C4.39543 4 3.5 4.89543 3.5 6V7H21.5V6C21.5 4.89543 20.6046 4 19.5 4H5.5ZM21.5 9H3.5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V9ZM6 13C6 12.4477 6.44772 12 7 12H8C8.55228 12 9 12.4477 9 13C9 13.5523 8.55228 14 8 14H7C6.44772 14 6 13.5523 6 13ZM11 13C11 12.4477 11.4477 12 12 12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H12C11.4477 14 11 13.5523 11 13ZM16 13C16 12.4477 16.4477 12 17 12H18C18.5523 12 19 12.4477 19 13C19 13.5523 18.5523 14 18 14H17C16.4477 14 16 13.5523 16 13ZM6 17C6 16.4477 6.44772 16 7 16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H7C6.44772 18 6 17.5523 6 17ZM11 17C11 16.4477 11.4477 16 12 16H13C13.5523 16 14 16.4477 14 17C14 17.5523 13.5523 18 13 18H12C11.4477 18 11 17.5523 11 17ZM16 17C16 16.4477 16.4477 16 17 16H18C18.5523 16 19 16.4477 19 17C19 17.5523 18.5523 18 18 18H17C16.4477 18 16 17.5523 16 17Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
