import type { IconProps } from '@core/types/components'

export function DownloadIcon({
  className = 'icon',
  size = 24,
  color = 'var(--color-primary)'
}: IconProps) {
  return (
    <i style={{ width: size, height: size }} className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.33333 11.3333C3.33333 12.0697 3.93029 12.6667 4.66667 12.6667L11.3333 12.6667C12.0697 12.6667 12.6667 12.0697 12.6667 11.3333L12.6667 10.6667C12.6667 10.2985 12.9651 10 13.3333 10C13.7015 10 14 10.2985 14 10.6667L14 11.3333C14 12.8061 12.8061 14 11.3333 14L4.66667 14C3.19391 14 2 12.8061 2 11.3333L2 10.6667C2 10.2985 2.29848 10 2.66667 10C3.03486 10 3.33333 10.2985 3.33333 10.6667L3.33333 11.3333ZM4.86193 7.5286C5.12228 7.26825 5.54439 7.26825 5.80474 7.5286L7.33333 9.05719L7.33333 2.66667C7.33333 2.29848 7.63181 2 8 2C8.36819 2 8.66667 2.29848 8.66667 2.66667L8.66667 9.05719L10.1953 7.5286C10.4556 7.26825 10.8777 7.26825 11.1381 7.5286C11.3984 7.78894 11.3984 8.21105 11.1381 8.4714L8.4714 11.1381C8.21105 11.3984 7.78894 11.3984 7.5286 11.1381L4.86193 8.4714C4.60158 8.21106 4.60158 7.78895 4.86193 7.5286Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
