import { type IconProps } from '@core/types/components'

export const CheckCircleIcon = ({
  className,
  color = 'var(--color-text-primary)',
  size = 14
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/check-circle'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99967 2.66634C5.05416 2.66634 2.66634 5.05416 2.66634 7.99967C2.66634 10.9452 5.05416 13.333 7.99967 13.333C10.9452 13.333 13.333 10.9452 13.333 7.99967C13.333 5.05416 10.9452 2.66634 7.99967 2.66634ZM1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967ZM10.4711 6.19494C10.7314 6.45529 10.7314 6.8774 10.4711 7.13775L7.80441 9.80441C7.54406 10.0648 7.12195 10.0648 6.8616 9.80441L5.52827 8.47108C5.26792 8.21073 5.26792 7.78862 5.52827 7.52827C5.78862 7.26792 6.21073 7.26792 6.47108 7.52827L7.33301 8.3902L9.52827 6.19494C9.78862 5.93459 10.2107 5.93459 10.4711 6.19494Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
