import { type IconProps } from '@core/types/components'

export const ClockOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.0013 3.33268C6.3194 3.33268 3.33464 6.31745 3.33464 9.99935C3.33464 13.6812 6.3194 16.666 10.0013 16.666C13.6832 16.666 16.668 13.6812 16.668 9.99935C16.668 6.31745 13.6832 3.33268 10.0013 3.33268ZM1.66797 9.99935C1.66797 5.39698 5.39893 1.66602 10.0013 1.66602C14.6037 1.66602 18.3346 5.39698 18.3346 9.99935C18.3346 14.6017 14.6037 18.3327 10.0013 18.3327C5.39893 18.3327 1.66797 14.6017 1.66797 9.99935ZM10.0013 5.83268C10.4615 5.83268 10.8346 6.20578 10.8346 6.66602V9.65417L13.0906 11.9101C13.416 12.2355 13.416 12.7632 13.0906 13.0886C12.7651 13.414 12.2375 13.414 11.912 13.0886L9.41205 10.5886C9.25577 10.4323 9.16797 10.2204 9.16797 9.99935V6.66602C9.16797 6.20578 9.54106 5.83268 10.0013 5.83268Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
