export const TOKEN_EXPIRATION_TIME = Number(process.env.NEXT_PUBLIC_TOKEN_EXPIRATION ?? '')

export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN ?? ''

export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV ?? ''

export const AVIVA_WEB_URL = process.env.NEXT_PUBLIC_AVIVA_WEB_URL ?? ''

export const isDeployed = NODE_ENV === 'production' || NODE_ENV === 'test'

export const IZIPAY_PUBLIC_KEY = process.env.NEXT_PUBLIC_IZIPAY_PUBLIC ?? ''
export const IZIPAY_SCRIPT = process.env.NEXT_PUBLIC_IZIPAY_SCRIPT ?? ''
export const IZIPAY_MERCHANT_CODE = process.env.NEXT_PUBLIC_IZIPAY_MERCHANT_CODE ?? ''
export const IZIPAY_IPN = process.env.NEXT_PUBLIC_URL_IPN ?? ''

export const JWT_APPOINTMENTS_SECRET = process.env.JWT_APPOINTMENTS_SECRET ?? ''

export const RECATCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '6LfWiXInAAAAAFdAGBTPp_wjmETv5ZTt9HJJufMv'
export const RECATCHA_SECRET_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY ?? '6LfWiXInAAAAAPtZnJ6gydCXKGiC7rSX1YzfGEl0'

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? ''
