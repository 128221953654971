import { type IconProps } from '@core/types/components'

export const LocationOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.1248 5.04123C11.8467 2.76317 8.15327 2.76317 5.87521 5.04123C3.59715 7.31928 3.59715 11.0127 5.87521 13.2908L9.41145 16.827C9.73665 17.1522 10.2632 17.1524 10.5889 16.8267L14.1248 13.2908C16.4028 11.0127 16.4028 7.31928 14.1248 5.04123ZM4.6967 3.86271C7.62563 0.933783 12.3744 0.933783 15.3033 3.86271C18.2322 6.79165 18.2322 11.5404 15.3033 14.4693L12.3847 17.3879C12.3586 17.414 12.3962 17.3764 12.37 17.4026L11.7674 18.0052C10.7913 18.9813 9.20948 18.9821 8.23293 18.0056L4.6967 14.4693C1.76777 11.5404 1.76777 6.79165 4.6967 3.86271ZM10 7.49935C9.07953 7.49935 8.33333 8.24554 8.33333 9.16602C8.33333 10.0865 9.07953 10.8327 10 10.8327C10.9205 10.8327 11.6667 10.0865 11.6667 9.16602C11.6667 8.24554 10.9205 7.49935 10 7.49935ZM6.66667 9.16602C6.66667 7.32507 8.15905 5.83268 10 5.83268C11.8409 5.83268 13.3333 7.32507 13.3333 9.16602C13.3333 11.007 11.8409 12.4993 10 12.4993C8.15905 12.4993 6.66667 11.007 6.66667 9.16602Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
