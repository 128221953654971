import type { IconProps } from '@core/types/components'

interface Props extends IconProps {
  isSelected?: boolean
}

export function HeartIconToSelect({
  className = 'icon',
  size = 36,
  color = 'var(--color-text-body-b2)',
  isSelected = false
}: Props) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.70844 9.30903C8.52022 6.49725 13.079 6.49725 15.8908 9.30903L17.9996 11.4179L20.1084 9.30903C22.9202 6.49725 27.479 6.49725 30.2908 9.30903C33.1026 12.1208 33.1026 16.6796 30.2908 19.4914L17.9996 31.7825L5.70844 19.4914C2.89667 16.6796 2.89667 12.1208 5.70844 9.30903Z'
          fill={!isSelected ? 'var(--color-text-body-b2)' : color}
        />
      </svg>
    </i>
  )
}
