/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { type Dispatch, type SetStateAction, useEffect } from 'react'

import { useAuth } from '@core/contexts/auth/context'
import useMiniAlertStore from '@core/stores/miniAlert/useMiniAlertStore'
import useProgramsStore from '@core/stores/programs/useProgramsStore'
import { getSaludPrograms, getSaludProgramsAd } from '@services/programs'
import { type SaludProgram } from '@services/programs/types'

interface Params {
  programsExpired: SaludProgram[]
  setProgramsExpired: Dispatch<SetStateAction<SaludProgram[]>>
}

export const useSaludProgramsNotificattions = ({ setProgramsExpired, programsExpired }: Params) => {
  const { openAlert } = useMiniAlertStore()
  const { user } = useAuth()
  const { programs, setPrograms, isLoading, setIsLoading, setProgramsAd } = useProgramsStore()
  const getPrograms = async () => {
    if (!user?.token) throw new Error('No existe el usuario')
    setIsLoading(true)
    try {
      const programs = await getSaludPrograms({ token: user.token })
      setPrograms(programs)
    } catch (error: any) {
      console.error(error)
      getProgramsAd()
    } finally {
      setIsLoading(false)
    }
  }

  const getProgramsAd = async () => {
    try {
      const programsAd = await getSaludProgramsAd()
      setProgramsAd(programsAd)
    } catch (error: any) {
      console.error(error)
      openAlert(error.message as string)
    }
  }

  const onDeleteProgramNotification = (contractId: number) => {
    const newProgramsExpired = programsExpired.filter(program => program.contractId !== contractId)
    setProgramsExpired(newProgramsExpired)
  }

  useEffect(() => {
    if (!user) return
    getPrograms()
  }, [user])

  useEffect(() => {
    if (programs.length === 0) return

    const programsExpired = programs.filter(program => program.contracInDayStatudId === 1)
    setProgramsExpired(programsExpired)
  }, [programs])

  return {
    isLoading,
    programsExpired,
    programs,
    onDeleteProgramNotification
  }
}
