import { type IconProps } from '@core/types/components'

export const WarningIcon = ({
  className,
  color = 'var(--color-warning)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.40199 1.5C8.55669 -0.5 11.4434 -0.5 12.5981 1.5L19.5263 13.5C20.681 15.5 19.2377 18 16.9283 18H3.07186C0.762457 18 -0.680918 15.5 0.473782 13.5L7.40199 1.5ZM10.8661 2.5C10.4812 1.83333 9.51894 1.83333 9.13404 2.5L2.20583 14.5C1.82093 15.1667 2.30206 16 3.07186 16H16.9283C17.6981 16 18.1792 15.1667 17.7943 14.5L10.8661 2.5ZM10.0001 6C10.5523 6 11.0001 6.44771 11.0001 7V9C11.0001 9.55228 10.5523 10 10.0001 10C9.44778 10 9.00006 9.55228 9.00006 9V7C9.00006 6.44771 9.44778 6 10.0001 6ZM9.00006 13C9.00006 12.4477 9.44778 12 10.0001 12H10.0101C10.5623 12 11.0101 12.4477 11.0101 13C11.0101 13.5523 10.5623 14 10.0101 14H10.0001C9.44778 14 9.00006 13.5523 9.00006 13Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
