export const capitalizeAll = (str = ''): string => {
  if (!str) return ''

  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const truncateString = (text: string, maxChars: number): string => {
  if (maxChars === 0) return text
  let truncatedText = text.slice(0, maxChars)
  if (text.length > maxChars) {
    truncatedText += '...'
  }

  return truncatedText
}

export const removeWeirdSymbols = (text = ''): string => {
  // Remplaza caracteres acentuados por caracteres sin acento
  const newText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // Elimina todos los símbolos que no son alfanuméricos o espacios
  newText.replace(/[^a-zA-Z0-9\s]/g, '')

  return newText
}
