import { type IconProps } from '@core/types/components'

export const AdjustmentIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 2C4.36819 2 4.66667 2.29848 4.66667 2.66667V8.78047C5.44346 9.05503 6 9.79585 6 10.6667C6 11.5375 5.44346 12.2783 4.66667 12.5529V13.3333C4.66667 13.7015 4.36819 14 4 14C3.63181 14 3.33333 13.7015 3.33333 13.3333V12.5529C2.55654 12.2783 2 11.5375 2 10.6667C2 9.79585 2.55654 9.05503 3.33333 8.78047V2.66667C3.33333 2.29848 3.63181 2 4 2ZM8 2C8.36819 2 8.66667 2.29848 8.66667 2.66667V3.44714C9.44346 3.72169 10 4.46252 10 5.33333C10 6.20415 9.44346 6.94497 8.66667 7.21953V13.3333C8.66667 13.7015 8.36819 14 8 14C7.63181 14 7.33333 13.7015 7.33333 13.3333V7.21953C6.55654 6.94497 6 6.20415 6 5.33333C6 4.46252 6.55654 3.72169 7.33333 3.44714V2.66667C7.33333 2.29848 7.63181 2 8 2ZM12 2C12.3682 2 12.6667 2.29848 12.6667 2.66667V8.78047C13.4435 9.05503 14 9.79585 14 10.6667C14 11.5375 13.4435 12.2783 12.6667 12.5529V13.3333C12.6667 13.7015 12.3682 14 12 14C11.6318 14 11.3333 13.7015 11.3333 13.3333V12.5529C10.5565 12.2783 10 11.5375 10 10.6667C10 9.79585 10.5565 9.05503 11.3333 8.78047V2.66667C11.3333 2.29848 11.6318 2 12 2ZM8 4.66667C7.63181 4.66667 7.33333 4.96514 7.33333 5.33333C7.33333 5.70152 7.63181 6 8 6C8.36819 6 8.66667 5.70152 8.66667 5.33333C8.66667 4.96514 8.36819 4.66667 8 4.66667ZM4 10C3.63181 10 3.33333 10.2985 3.33333 10.6667C3.33333 11.0349 3.63181 11.3333 4 11.3333C4.36819 11.3333 4.66667 11.0349 4.66667 10.6667C4.66667 10.2985 4.36819 10 4 10ZM12 10C11.6318 10 11.3333 10.2985 11.3333 10.6667C11.3333 11.0349 11.6318 11.3333 12 11.3333C12.3682 11.3333 12.6667 11.0349 12.6667 10.6667C12.6667 10.2985 12.3682 10 12 10Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
