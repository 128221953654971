import { type MediaParams, type MoreMediaParams } from '@core/types/components'

import { useMedia } from './useMedia'

export const defaultMediaParams: MediaParams & MoreMediaParams = {
  isMobile: true,
  isTablet: false,
  isDesktop: false,

  isMobileSmall: true,
  isMobileMedium: false,
  isMobileLarge: false,

  isTabletSmall: false,
  isTabletMedium: false,
  isTabletLarge: false,

  isDesktopSmall: false,
  isDesktopMedium: false,
  isDesktopLarge: false
}

export function useIsMobile(defaultState = true): boolean {
  return useMedia('(max-width: 831px)', defaultState)
}

export function useIsTablet(): boolean {
  return useMedia('(min-width: 831) and (max-width: 1279px)', true)
}

export function useIsDesktop(): boolean {
  return useMedia('(min-width: 1280px)', true)
}

export function useMediaQuery(props: MediaParams): MediaParams & MoreMediaParams {
  const isMobile = useMedia('(max-width: 831px)', props.isMobile)
  const isTablet = useMedia('(min-width: 831px) and (max-width: 1279px)', props.isTablet)
  const isDesktop = useMedia('(min-width: 1280px)', props.isDesktop)

  const isMobileSmall = useMedia('(min-width: 320px) and (max-width: 419px)', props.isMobile)
  const isMobileMedium = useMedia('(min-width: 420px) and (max-width: 647px)', props.isMobile)
  const isMobileLarge = useMedia('(min-width: 648px) and (max-width: 831px)', props.isMobile)

  const isTabletSmall = useMedia('(min-width: 832px) and (max-width: 895px)', props.isTablet)
  const isTabletMedium = useMedia('(min-width: 896px) and (max-width: 1023px)', props.isTablet)
  const isTabletLarge = useMedia('(min-width: 1024px) and (max-width: 1279px)', props.isTablet)

  const isDesktopSmall = useMedia('(min-width: 1280px) and (max-width: 1365px)', props.isDesktop)
  const isDesktopMedium = useMedia('(min-width: 1366px) and (max-width: 1439px)', props.isDesktop)
  const isDesktopLarge = useMedia('(min-width: 1440px)', props.isDesktop)

  return {
    isMobile,
    isTablet,
    isDesktop,

    isMobileSmall,
    isMobileMedium,
    isMobileLarge,

    isTabletSmall,
    isTabletMedium,
    isTabletLarge,

    isDesktopSmall,
    isDesktopMedium,
    isDesktopLarge
  }
}
