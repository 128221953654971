import { type IconProps } from '@core/types/components'

export const CrossOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.16667 2.5C8.24619 2.5 7.5 3.24619 7.5 4.16667V7.5H4.16667C3.24619 7.5 2.5 8.24619 2.5 9.16667V10.8333C2.5 11.7538 3.24619 12.5 4.16667 12.5H7.5V15.8333C7.5 16.7538 8.24619 17.5 9.16667 17.5H10.8333C11.7538 17.5 12.5 16.7538 12.5 15.8333V12.5H15.8333C16.7538 12.5 17.5 11.7538 17.5 10.8333V9.16667C17.5 8.24619 16.7538 7.5 15.8333 7.5H12.5V4.16667C12.5 3.24619 11.7538 2.5 10.8333 2.5H9.16667Z'
          stroke={color}
          strokeWidth='1.8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  )
}
