import { type IconProps } from '@core/types/components'

export const ClinicalIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.3332 2.6665C12.2286 2.6665 11.3332 3.56194 11.3332 4.6665H8.6665C7.5619 4.6665 6.6665 5.56194 6.6665 6.6665V27.3332C6.6665 28.4378 7.5619 29.3332 8.6665 29.3332H23.3332C24.4378 29.3332 25.3332 28.4378 25.3332 27.3332V6.6665C25.3332 5.56194 24.4378 4.6665 23.3332 4.6665H20.6665C20.6665 3.56194 19.7711 2.6665 18.6665 2.6665H13.3332ZM12.6665 4.6665C12.6665 4.29832 12.965 3.99984 13.3332 3.99984H18.6665C19.0347 3.99984 19.3332 4.29832 19.3332 4.6665V5.99984C19.3332 6.36802 19.0347 6.6665 18.6665 6.6665H13.3332C12.965 6.6665 12.6665 6.36802 12.6665 5.99984V4.6665ZM12.6665 9.99984V11.9998H10.6665V13.3332H12.6665V15.3332H13.9998V13.3332H15.9998V11.9998H13.9998V9.99984H12.6665ZM11.3332 17.3332C10.965 17.3332 10.6665 17.6316 10.6665 17.9998C10.6665 18.368 10.965 18.6665 11.3332 18.6665H20.6665C21.0347 18.6665 21.3332 18.368 21.3332 17.9998C21.3332 17.6316 21.0347 17.3332 20.6665 17.3332H11.3332ZM10.6665 21.3332C10.6665 20.965 10.965 20.6665 11.3332 20.6665H20.6665C21.0347 20.6665 21.3332 20.965 21.3332 21.3332C21.3332 21.7014 21.0347 21.9998 20.6665 21.9998H11.3332C10.965 21.9998 10.6665 21.7014 10.6665 21.3332ZM11.3332 23.9998C10.965 23.9998 10.6665 24.2983 10.6665 24.6665C10.6665 25.0347 10.965 25.3332 11.3332 25.3332H20.6665C21.0347 25.3332 21.3332 25.0347 21.3332 24.6665C21.3332 24.2983 21.0347 23.9998 20.6665 23.9998H11.3332Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
