import { type IconProps } from '@core/types/components'

export const DocumentOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.01811 3.01811C6.67 2.36623 7.55414 2 8.47605 2H18.4994H18.5C18.5278 2 18.5555 2.00119 18.583 2.00356C18.8099 2.02303 19.0237 2.12187 19.1859 2.28412L26.7039 9.80209C26.8858 9.98401 26.988 10.2307 26.988 10.488V25.524C26.988 26.4459 26.6218 27.33 25.9699 27.9819C25.318 28.6338 24.4339 29 23.512 29H8.47605C7.55414 29 6.67 28.6338 6.01811 27.9819C5.36623 27.33 5 26.4459 5 25.524V5.47605C5 4.55414 5.36623 3.67 6.01811 3.01811ZM8.47605 3.94012H17.5293V10.488C17.5293 11.0238 17.9636 11.4581 18.4994 11.4581H25.0479V25.524C25.0479 25.9313 24.8861 26.322 24.598 26.61C24.31 26.8981 23.9193 27.0599 23.512 27.0599H8.47605C8.06869 27.0599 7.67803 26.8981 7.38998 26.61C7.10194 26.322 6.94012 25.9313 6.94012 25.524V5.47605C6.94012 5.06869 7.10194 4.67802 7.38998 4.38998C7.67802 4.10194 8.06869 3.94012 8.47605 3.94012ZM19.4694 5.31136L23.676 9.51796H19.4694V5.31136ZM10.9814 15.7836C10.4457 15.7836 10.0113 16.2179 10.0113 16.7536C10.0113 17.2894 10.4457 17.7237 10.9814 17.7237H21.0054C21.5411 17.7237 21.9754 17.2894 21.9754 16.7536C21.9754 16.2179 21.5411 15.7836 21.0054 15.7836H10.9814ZM10.0113 21.765C10.0113 21.2292 10.4457 20.7949 10.9814 20.7949H21.0054C21.5411 20.7949 21.9754 21.2292 21.9754 21.765C21.9754 22.3007 21.5411 22.735 21.0054 22.735H10.9814C10.4457 22.735 10.0113 22.3007 10.0113 21.765ZM10.9814 10.7713C10.4457 10.7713 10.0113 11.2056 10.0113 11.7413C10.0113 12.2771 10.4457 12.7114 10.9814 12.7114H12.2344H13.4874C14.0231 12.7114 14.4575 12.2771 14.4575 11.7413C14.4575 11.2056 14.0231 10.7713 13.4874 10.7713H12.2344H10.9814Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
