import { create } from 'zustand'

import { type MiniAlertProps } from '@core/components/ui/CustomDialog/MiniAlertError/MiniAlert'

type AlertState = Omit<MiniAlertProps, 'setOpen'>

export type MiniAlertConfigProps = Omit<AlertState, 'open' | 'description'>

type Actions = {
  openAlert: (message: string, config?: MiniAlertConfigProps) => void
  closeAlert: () => void
  resetAlert: () => void
}
const defaultValues: AlertState = {
  description: '',
  open: false,
  type: 'error',
  title: 'Error',
  icon: undefined,
  onClose: undefined
}

const useMiniAlertStore = create<AlertState & Actions>(set => ({
  ...defaultValues,
  openAlert: (message, config) => set(() => ({ open: true, description: message, ...config })),
  closeAlert: () => set(() => ({ open: false })),
  resetAlert: () => set(() => defaultValues)
}))

type AlertPropsSelector = Omit<MiniAlertProps, 'openLoader' | 'closeLoader' | 'setOpen'>

export const alertPropsSelector = (state: AlertState & Actions): AlertPropsSelector => ({
  open: state.open,
  description: state.description,
  onClose: state.onClose,
  title: state.title,
  type: state.type
})

export default useMiniAlertStore
