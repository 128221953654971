import { type FC } from 'react'

import { type IconProps } from '@core/types/components'

export const ArrowRight: FC<IconProps> = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 19 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.4577 0.921884C10.823 0.571362 11.4033 0.58333 11.7538 0.948617L17.9111 7.36528C18.2515 7.71996 18.2515 8.27996 17.9111 8.63464L11.7538 15.0513C11.4033 15.4166 10.823 15.4286 10.4577 15.078C10.0924 14.7275 10.0805 14.1472 10.431 13.7819L15.0717 8.94572H1.66642C1.16016 8.94572 0.749756 8.53531 0.749756 8.02905C0.749756 7.52279 1.16016 7.11238 1.66642 7.11238H15.1276L10.431 2.21797C10.0805 1.85268 10.0924 1.27241 10.4577 0.921884Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
