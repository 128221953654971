import { type IconProps } from '@core/types/components'

export const ShieldIcon = ({ className, color = 'var(--color-primary)', size = 24 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/shield-check'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.55502 1.46581C7.80814 1.23926 8.19113 1.23926 8.44424 1.46581C9.74209 2.62744 11.4544 3.33299 13.333 3.33299C13.4599 3.33299 13.586 3.32977 13.7112 3.32342C14.0272 3.30739 14.311 3.5158 14.3903 3.82218C14.5706 4.51866 14.6663 5.24848 14.6663 5.99968C14.6663 10.0389 11.9037 13.4312 8.16585 14.3933C8.05684 14.4213 7.94251 14.4213 7.8335 14.3933C4.09565 13.4312 1.33301 10.0389 1.33301 5.99968C1.33301 5.24845 1.42874 4.5186 1.60903 3.8221C1.68834 3.51571 1.97212 3.30731 2.2882 3.32334C2.41339 3.32969 2.53946 3.33291 2.66634 3.33291C4.54495 3.33291 6.25718 2.62739 7.55502 1.46581ZM2.78731 4.66541C2.7079 5.09768 2.66634 5.54358 2.66634 5.99968C2.66634 9.3557 4.9213 12.1867 7.99968 13.0571C11.0781 12.1867 13.333 9.3557 13.333 5.99968C13.333 5.5436 13.2915 5.09773 13.2121 4.66549C11.2479 4.6386 9.44051 3.95766 7.99962 2.83136C6.55875 3.95761 4.75143 4.63852 2.78731 4.66541ZM10.4711 6.19494C10.7314 6.45529 10.7314 6.8774 10.4711 7.13775L7.80441 9.80442C7.54406 10.0648 7.12195 10.0648 6.8616 9.80442L5.52827 8.47109C5.26792 8.21074 5.26792 7.78863 5.52827 7.52828C5.78862 7.26793 6.21073 7.26793 6.47108 7.52828L7.33301 8.39021L9.52827 6.19494C9.78862 5.9346 10.2107 5.9346 10.4711 6.19494Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
