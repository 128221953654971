import { type ReactNode } from 'react'
import Head from 'next/head'

import { type SEOMeta } from '@core/types/seo'

import OGImage from './OGImage'

type SEOProps = {
  config: SEOMeta
  children: ReactNode
}

export default function SEO({ config, children }: SEOProps) {
  const {
    title,
    description,
    keywords,
    openGraph,
    twitter,
    statusBarStyle,
    tileColor,
    navbuttonColor,
    themeColor,
    robots
  } = config

  return (
    <Head>
      <title key='title'>{title}</title>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      />
      <meta key='description' name='description' content={description} />
      {keywords && <meta key='keywords' name='keywords' content={keywords} />}
      <meta key='mobile-web-app-capable' name='mobile-web-app-capable' content='yes' />
      <meta key='apple-mobile-web-app-capable' name='apple-mobile-web-app-capable' content='yes' />
      {statusBarStyle && (
        <meta
          key='apple-mobile-web-app-status-bar-style'
          name='apple-mobile-web-app-status-bar-style'
          content={statusBarStyle}
        />
      )}
      {tileColor && (
        <meta key='msapplication-TileColor' name='msapplication-TileColor' content={tileColor} />
      )}
      {navbuttonColor && (
        <meta
          key='msapplication-navbutton-color'
          name='msapplication-navbutton-color'
          content={navbuttonColor}
        />
      )}
      {themeColor && <meta key='theme-color' name='theme-color' content={themeColor} />}
      <meta key='Content-type' httpEquiv='Content-type' content='text/html; charset=utf-8' />
      <meta key='Cache-control' httpEquiv='Cache-control' content='public' />

      <meta key='og:title' property='og:title' content={openGraph.title} />
      <meta key='og:description' property='og:description' content={openGraph.description} />
      <meta key='og:type' property='og:type' content={openGraph.type} />
      <meta key='og:url' property='og:url' content={openGraph.url} />
      <meta key='og:site_name' property='og:site_name' content={openGraph.siteName} />
      {openGraph.locale && <meta key='og:locale' property='og:locale' content={openGraph.locale} />}
      {openGraph.images.length > 0 &&
        openGraph.images.map(({ url, width, height, alt }, i) => (
          <OGImage key={i} id={i} url={url} width={width} height={height} alt={alt} />
        ))}
      {twitter.cardType && (
        <meta key='twitter:card' name='twitter:card' content={twitter.cardType} />
      )}
      {twitter.site && <meta key='twitter:site' name='twitter:site' content={twitter.site} />}
      {twitter.handle && (
        <meta key='twitter:creator' name='twitter:creator' content={twitter.handle} />
      )}
      <meta key='robots' name='robots' content={robots ?? 'index,follow'} />
      <meta key='googlebot' name='googlebot' content={robots ?? 'index,follow'} />

      <link rel='shortcut icon' href='/favicon.ico' />

      {/* <link rel='icon' type='image/x-icon' href='favicon.ico' />
      <link rel='icon' type='image/png' sizes='16x16' href='favicon-16x16.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='favicon-32x32.png' />
      <link rel='mask-icon' type='image/svg' sizes='693x693' href='mask-icon.svg' />
      <link rel='apple-touch-icon' type='image/svg' sizes='180x180' href='apple-touch-icon.svg' /> */}
      {children}
    </Head>
  )
}
