import { type IconProps } from '@core/types/components'

export const TrashIcon = ({ className, color = 'var(--color-primary)', size = 24 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/trash'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6 3C6 2.17157 6.67157 1.5 7.5 1.5H10.5C11.3284 1.5 12 2.17157 12 3V4.5H14.2423C14.2469 4.49996 14.2515 4.49996 14.2562 4.5H15C15.4142 4.5 15.75 4.83579 15.75 5.25C15.75 5.66421 15.4142 6 15 6H14.9483L14.3476 14.4103C14.2635 15.5877 13.2838 16.5 12.1033 16.5H5.89668C4.71624 16.5 3.7365 15.5877 3.6524 14.4103L3.05166 6H3C2.58579 6 2.25 5.66421 2.25 5.25C2.25 4.83579 2.58579 4.5 3 4.5H3.74381C3.74846 4.49996 3.75311 4.49996 3.75774 4.5H6V3ZM4.55548 6L5.14858 14.3034C5.17662 14.6959 5.5032 15 5.89668 15H12.1033C12.4968 15 12.8234 14.6959 12.8514 14.3034L13.4445 6H4.55548ZM10.5 4.5H7.5V3H10.5V4.5ZM7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5ZM10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
