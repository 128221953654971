/* eslint-disable @typescript-eslint/indent */
import { type FC } from 'react'

import { Dialog, Stack, Typography } from '@mui/material'
import { CloseIcon, SuccessIcon2, WarningIcon2 } from '@core/components/icons'
import { colors } from '@core/helpers'
import useMiniAlertStore from '@core/stores/miniAlert/useMiniAlertStore'

export interface MiniAlertProps {
  title?: string
  description: string
  open: boolean
  type?: 'success' | 'error' | 'warning' | 'info'
  icon?: (size: number) => JSX.Element
  onClose?: () => void
}

export const MiniAlert: FC<MiniAlertProps> = ({
  title,
  description,
  open,
  icon,
  onClose,
  type = 'error'
}) => {
  const { closeAlert, resetAlert } = useMiniAlertStore()

  const handleOnClose = () => {
    if (typeof onClose === 'function') onClose()
    closeAlert()
    setTimeout(() => {
      resetAlert()
    }, 200)
  }

  return (
    <Dialog open={open} maxWidth='phone_lg' onClose={handleOnClose}>
      <Stack p='2.4rem' width='29.2rem'>
        <Stack
          onClick={handleOnClose}
          component='div'
          alignSelf='flex-end'
          sx={{ cursor: 'pointer' }}
        >
          <CloseIcon size={18} />
        </Stack>

        <Stack gap='1.6rem' p='2.4rem' alignItems='center'>
          {icon ? (
            icon(80)
          ) : type === 'success' ? (
            <SuccessIcon2 color={colors.primary_light} size={80} />
          ) : (
            <WarningIcon2
              color={
                type === 'info'
                  ? colors.primary
                  : type === 'error'
                  ? colors.error
                  : type === 'warning'
                  ? colors.warning
                  : colors.text_body_b2
              }
              size={80}
            />
          )}
          <Typography
            color={
              type === 'info'
                ? colors.primary
                : type === 'error'
                ? colors.error
                : type === 'warning'
                ? colors.warning
                : colors.text_body_b2
            }
            variant='h3_bold'
            textAlign='center'
          >
            {title ?? type === 'info'
              ? 'Información'
              : type === 'warning'
              ? 'Cuidado'
              : type === 'success'
              ? 'Correcto'
              : 'Error'}
          </Typography>
          <Typography textAlign='center' variant='body2' color={colors.text_body_b2}>
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  )
}
