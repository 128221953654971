import { type IconProps } from '@core/types/components'

export const SuccessIcon2 = ({
  className,
  color = 'var(--color-success)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M40 72C57.6731 72 72 57.6731 72 40C72 22.3269 57.6731 8 40 8C22.3269 8 8 22.3269 8 40C8 57.6731 22.3269 72 40 72ZM54.8284 34.8284C56.3905 33.2663 56.3905 30.7337 54.8284 29.1716C53.2663 27.6095 50.7337 27.6095 49.1716 29.1716L36 42.3431L30.8284 37.1716C29.2663 35.6095 26.7337 35.6095 25.1716 37.1716C23.6095 38.7337 23.6095 41.2663 25.1716 42.8284L33.1716 50.8284C34.7337 52.3905 37.2663 52.3905 38.8284 50.8284L54.8284 34.8284Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
