/* eslint-disable prefer-promise-reject-errors */
import { type ParsedUrlQuery } from 'querystring'

import { type GetServerSidePropsContext, type PreviewData } from 'next'

import axios from 'axios'
import { deleteCookie } from 'cookies-next'
import jwt from 'jsonwebtoken'

import { getAPIUrl } from '@core/lib/api'
import { isEmptyValue } from '@core/lib/utils'
import { validateAvivaJWT } from '@services/login'
import { type PatientData } from '@services/user/types'

import { sessionCookieOptions } from './cookie'
import { capitalizeAll } from './text'

export const formatJWT = (token: string): string => {
  const tokenWithoutJWT = token.split(' ')[1]

  return tokenWithoutJWT
}

export const generateJWT = async (
  payload: Record<string, any>,
  expDate?: string,
  secret?: string
): Promise<{ jwt: string }> => {
  try {
    const url = getAPIUrl('/jwt/generate')
    const { data } = await axios.post<{ jwt: string }>(url, { payload, expDate, secret })

    return data
  } catch (error) {
    console.error(error)
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message as string)
    }
    throw error
  }
}

export const getUserFromSSRCookies = async (
  ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
): Promise<PatientData | undefined> => {
  const { req, res } = ctx

  const jwt = req.cookies.session

  if (!jwt) return

  try {
    const user = await validateAvivaJWT(jwt)
    user.token = jwt
    if (!user) throw new Error('Token inválido')

    return {
      ...user,
      name: capitalizeAll(user.name.toLowerCase()),
      surname1: capitalizeAll(user.surname1.toLowerCase()),
      surname2: capitalizeAll(user.surname2.toLowerCase())
    }
  } catch (error) {
    console.error(error)
    deleteCookie('session', { req, res, ...sessionCookieOptions })
  }
}

export const isValidToken = (token: string, secret: string): Promise<unknown> => {
  // Comprueba que exista el token

  if (isEmptyValue(token)) {
    throw new Error('No vino el token')
  }

  if (token.length <= 10) return Promise.reject('JWT is not valid')

  return new Promise((resolve, reject) => {
    try {
      jwt.verify(token, secret, (err, payload) => {
        if (err) {
          reject('JWT is not valid')

          return
        }

        resolve(payload as unknown)
      })
    } catch (error) {
      reject('JWT is not valid')
    }
  })
}
