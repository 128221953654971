import { type IconProps } from '@core/types/components'

export const Stethoscope2OutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.83329 2.91602H3C2.44772 2.91602 2 3.36373 2 3.91602V7.49925C2 10.0305 4.05198 12.0825 6.58323 12.0825V12.0825C9.11448 12.0825 11.1665 10.0305 11.1665 7.49925V3.91601C11.1665 3.36373 10.7188 2.91602 10.1665 2.91602H9.33317'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.58203 12.084V13.459C6.58203 16.2433 8.83921 18.5005 11.6236 18.5005V18.5005C14.408 18.5005 16.6651 16.2433 16.6651 13.459V12.084'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.41797 2V3.83329'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.75 2V3.83329'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle
          cx='16.6653'
          cy='10.2493'
          r='1.83329'
          stroke={color}
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  )
}
