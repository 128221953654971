/* eslint-disable @typescript-eslint/indent */
import { avivaAppointmentService } from 'src/api/server'

import { type AppointmentNotificationResponse } from '@core/types/notifications'
import { type AvivaServerResponse } from '@core/types/server/res'

export const getAppointmentNotifications =
  async (): Promise<AppointmentNotificationResponse | null> => {
    const { data } = await avivaAppointmentService.get<
      AvivaServerResponse<AppointmentNotificationResponse>
    >('/appointment/notifications')

    return data.data
  }
