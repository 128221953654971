import axios from 'axios'
import { avivaUserService } from 'src/api/server'

import { handleServerError } from '@core/helpers/errors'
import { SERVER_ERROR_MESSAGE } from '@core/lib/utils'
import { type MiniAlertConfigProps } from '@core/stores/miniAlert/useMiniAlertStore'
import { type AvivaServerResponse } from '@core/types/server/res'
import { type PatientData } from '@services/user/types'

import { type LoginUserDataRes, type LoginWithCodeDataRes, type RecoveryPasswordRes } from './types'

interface LoginUserBody {
  password: string
  userName: string
  documentType: number
}

export const loginUser = async (body: LoginUserBody): Promise<LoginUserDataRes> => {
  try {
    const { data } = await avivaUserService.post<AvivaServerResponse<LoginUserDataRes>>(
      '/login/user',
      body
    )
    if (!data.valid || !data.data) {
      throw new Error('Error iniciando sesion')
    }

    return data.data
  } catch (error: any) {
    console.error(error.message)

    if (axios.isAxiosError(error)) {
      const messageError = error.response?.data.message as string

      throw new Error(messageError)
    }
    throw new Error(SERVER_ERROR_MESSAGE)
  }
}

interface RecoveryPasswordBody {
  documentType: number
  documentNumber: string
}

export const sendResetPasswordEmail = async (
  body: RecoveryPasswordBody,
  openAlert: (message: string, config?: MiniAlertConfigProps | undefined) => void
): Promise<RecoveryPasswordRes | undefined> => {
  try {
    const { data } = await avivaUserService.post<AvivaServerResponse<RecoveryPasswordRes>>(
      '/recovery/getInformation',
      body
    )
    if (!data.valid || !data.data) {
      throw new Error('Error enviando email')
    }

    return data.data
  } catch (error: any) {
    handleServerError(error, 'normal', openAlert)
  }
}

interface ValidateCodeAndChangePassBody {
  idToken: string
  code: string
  newPasswordHash: string
}

export const validateCodeAndChangePass = async (
  body: ValidateCodeAndChangePassBody
): Promise<void> => {
  try {
    const { data } = await avivaUserService.post<AvivaServerResponse<null>>(
      '/recovery/password',
      body
    )

    if (!data.valid) {
      throw new Error('Error validando el codigo')
    }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const messageError = error.response?.data.message as string
      throw new Error(messageError)
    }
    throw new Error(error.message as string)
  }
}

interface ValidateCodeAndLoginBody {
  idToken: string
  code: string
}

export const validateCodeAndLogin = async (
  body: ValidateCodeAndLoginBody
): Promise<LoginWithCodeDataRes> => {
  try {
    const { data } = await avivaUserService.post<AvivaServerResponse<LoginWithCodeDataRes>>(
      '/validate/user/code',
      body
    )

    if (!data.valid || !data.data) {
      throw new Error('Error validando el codigo')
    }

    return data.data
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const messageError = error.response?.data.message as string
      throw new Error(messageError)
    }
    throw new Error(error.message as string)
  }
}

export const validateAvivaJWT = async (jwt: string): Promise<PatientData> => {
  try {
    const { data } = await avivaUserService.get<AvivaServerResponse<PatientData>>(
      '/validate/user/token',
      {
        params: { jwt }
      }
    )

    if (!data.valid || !data.data) {
      throw new Error('Token inválido')
    }

    return data.data
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const messageError = error.response?.data.message as string
      throw new Error(messageError)
    }
    throw new Error(error.message as string)
  }
}
