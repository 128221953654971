import { type IconProps } from '@core/types/components'

export const UserOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.9987 3.33268C8.61799 3.33268 7.4987 4.45197 7.4987 5.83268C7.4987 7.21339 8.61799 8.33268 9.9987 8.33268C11.3794 8.33268 12.4987 7.21339 12.4987 5.83268C12.4987 4.45197 11.3794 3.33268 9.9987 3.33268ZM5.83203 5.83268C5.83203 3.5315 7.69751 1.66602 9.9987 1.66602C12.2999 1.66602 14.1654 3.5315 14.1654 5.83268C14.1654 8.13387 12.2999 9.99935 9.9987 9.99935C7.69751 9.99935 5.83203 8.13387 5.83203 5.83268ZM5.06783 16.666H14.9296C14.5328 14.3013 12.4762 12.4993 9.9987 12.4993C7.5212 12.4993 5.46456 14.3013 5.06783 16.666ZM3.33203 17.4993C3.33203 13.8175 6.3168 10.8327 9.9987 10.8327C13.6806 10.8327 16.6654 13.8175 16.6654 17.4993C16.6654 17.9596 16.2923 18.3327 15.832 18.3327H4.16536C3.70513 18.3327 3.33203 17.9596 3.33203 17.4993Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
