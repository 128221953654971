import { type IconProps } from '@core/types/components'

export const CheckIcon = ({ className, color = 'var(--color-primary)', size = 20 }: IconProps) => {
  return (
    <i className={className}>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none'>
        <path
          fill={color}
          fillRule='evenodd'
          d='M3.293 11.606a1 1 0 0 1 1.414 0l4.95 4.95L20.264 5.95a1 1 0 1 1 1.414 1.414L9.657 19.384l-6.364-6.363a1 1 0 0 1 0-1.415Z'
          clipRule='evenodd'
        />
      </svg>
    </i>
  )
}
