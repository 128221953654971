import { type PaletteOptions } from '@mui/material'

export const MuiPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#3497c3'
  },
  secondary: {
    main: '#004b73'
  },
  error: {
    main: '#ff3b3b'
  },
  warning: {
    main: '#ffb82e'
  },
  info: {
    main: '#086fdd'
  },
  success: {
    main: '#06c270'
  },
  text: {
    secondary: '#808B8F',
    primary: '#364145',
    disabled: '#98A1A4'
  }
}
