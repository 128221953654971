export const AVIVA_SERVER_USER_URL = process.env.NEXT_PUBLIC_AVIVA_SERVER_USER ?? ''
export const AVIVA_SERVER_APPOINTMENT_URL = process.env.NEXT_PUBLIC_AVIVA_SERVER_APPOINTMENT ?? ''

export const API_URL = (process.env.NEXT_PUBLIC_API_URL as string) ?? 'http://localhost:5003/api'

export const getAPIUrl = (url: string): string => {
  return `${API_URL}${url}`
}

export const getAvivaServerUrl = (url?: string, service?: 'user' | 'appointment'): string => {
  const SERVER_URL = service === 'user' ? AVIVA_SERVER_USER_URL : AVIVA_SERVER_APPOINTMENT_URL

  return `${SERVER_URL}/api/v1${url ?? ''}`
}
