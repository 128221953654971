import { type SEOMeta } from '@core/types/seo'

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL ?? 'http://localhost:3000'
const IMAGE_URL = 'https://s3.amazonaws.com/static.aviva.pe/assets/aviva-background-open.jpg'

export const SEO_META: SEOMeta = {
  title: 'Portal Mi aviva',
  description:
    'En tu portal web podrás acceder a tus servicios y ver los detalles de tus citas históricas y programadas',
  keywords:
    'clínica, aviva, portal, miaviva, centro de salud, hospital, perú, peruanos, corazón, salud',
  openGraph: {
    title: 'Clínica aviva',
    description: 'En clínica aviva te brindamos un servicio de calidad a tu alcance',
    type: 'website',
    url: SITE_URL,
    siteName: 'Clínica aviva',
    locale: 'es_ES',
    images: [
      {
        url: IMAGE_URL,
        width: 1200,
        height: 630,
        alt: 'Profesional de aviva'
      }
    ]
  },
  twitter: {
    handle: 'Clínica aviva',
    site: 'Clínica aviva',
    cardType: 'summary_large_image'
  },
  statusBarStyle: 'default',
  tileColor: '#3497c3',
  navbuttonColor: '#3497c3',
  themeColor: '#3497c3'
}

export function getSEOConfig(config?: SEOMeta): SEOMeta {
  if (!config) return SEO_META

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    ...SEO_META,
    ...config
  } as SEOMeta
}
