export const colors = {
  white: 'var(--color-white)',
  black: 'var(--color-black)',

  primary_default: 'var(--color-primary-default)',
  primary_darkest: 'var(--color-primary-darkest)',
  primary_dark: 'var(--color-primary-dark)',
  primary: 'var(--color-primary)',
  primary_light: 'var(--color-primary-light)',
  primary_lighter: 'var(--color-primary-lighter)',
  primary_lightest: 'var(--color-primary-lightest)',
  primary_100: 'var(--color-primary-100)',
  primary_button_active: 'var(--color-primary-button-active)',

  // Brand secondary colors
  secondary_3_lightest: 'var(--color-secondary-3-lightest)',
  secondary_3_medium: 'var(--color-secondary-3-medium)',
  secondary_3_darkest: 'var(--color-secondary-3-darkest)',
  secondary_dark: 'var(--color-secondary-dark)',

  orange_secondary_dark: 'var(--color-orange-secondary-dark)',

  secondary_2_lightest: 'var(--color-secondary-2-lightest)',
  // Orange
  secondary_orange: 'var(--color-secondary-orange)',
  // Blue
  secondary_blue: 'var(--color-secondary-blue)',

  // BG Colors
  background_bg_s2: 'var(--color-background-bg-s2)',
  background_bg_s3: 'var(--color-background-bg-s3)',
  background_bg_p1: 'var(--color-background-bg-p1)',
  background_bg_n1: 'var( --color-background-bg-n1)',

  // Status colors
  warning: 'var(--color-warning)',
  success: 'var(--color-success)',
  error: 'var(--color-error)',
  info: 'var(--color-info)',
  informative: 'var(--color-informative)',

  // Text colors
  text_title: 'var(--color-text-title)',
  text_title_p1: 'var(--color-text-title-p1)',
  text_title_p2: 'var(--color-text-title-p2)',
  text_primary: 'var(--color-text-primary)',
  text_body_b1: 'var(--color-text-body-b1)',
  text_body_b2: 'var(--color-text-body-b2)',
  text_body_b3: 'var(--color-text-body-b3)',
  text_secondary: 'var(--color-text-secondary)',

  // neutrals colors
  neutral_gray_02: 'var(--color-neutral-gray-02)',
  neutral_gray_03: 'var(--color-neutral-gray-03)',
  neutral_gray_08: 'var(--color-neutral-gray-08)',
  neutral_gray_09: 'var(--color-neutral-gray-09)',

  // transparencies
  transparency_dark_75: 'var( --transparency-dark-75)',

  // disabled
  disabled_bg: 'var(--color-disabled-bg)'
}
