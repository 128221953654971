import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useRouter } from 'next/router'

import { deleteCookie, setCookie } from 'cookies-next'
import isEmpty from 'just-is-empty'

import { sessionCookieOptions } from '@core/helpers/cookie'
import { formatJWT } from '@core/helpers/jwt'
import { type PageProps } from '@core/types/components'
import { type ContextProps, type ContextReducer, type ContextReducers } from '@core/types/context'
import { type PatientData } from '@services/user/types'

import { type AuthContextValue, type AuthState } from './types'

const ACTIONS = {
  SET_USER: 'SET_USER'
}

const ACTIONS_REDUCERS: ContextReducers<AuthState> = {
  [ACTIONS.SET_USER]: (state, action) => ({
    ...state,
    user: action.payload
  })
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AuthContext = createContext<AuthContextValue>({} as AuthContextValue)

AuthContext.displayName = 'AuthContext'

const layoutReducer: ContextReducer<AuthState> = (state, action) => {
  const actionReducer = ACTIONS_REDUCERS[action.type]

  return typeof actionReducer === 'function' ? actionReducer(state, action) : state
}

const getInitialState = (pageProps: PageProps | undefined): AuthState => {
  const user = pageProps?.user ?? null

  return {
    user
  }
}

export const AuthProvider = ({ pageProps, ...props }: ContextProps) => {
  const router = useRouter()
  const [state, dispatch] = useReducer(layoutReducer, getInitialState(pageProps ?? {}))

  const setUser = useCallback(
    (payload: PatientData | null) => dispatch({ type: ACTIONS.SET_USER, payload }),
    [dispatch]
  )

  useEffect(() => {
    if (!pageProps?.user) return
    setUser(pageProps?.user)
  }, [pageProps?.user])

  const logOutUser = () => {
    if (typeof location === 'undefined') {
      throw new Error('This function only can be actioned by cliend side')
    }
    deleteCookie('session', sessionCookieOptions)
    router.reload()
  }

  const setAuthTokenToCookie = (jwt: string): void => {
    const token = formatJWT(jwt)

    setCookie('session', token, sessionCookieOptions)
  }
  // Reset state
  const value = useMemo<AuthContextValue>(
    () => ({
      ...state,
      setUser,
      logOutUser,
      setAuthTokenToCookie
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext)

  if (isEmpty(context)) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export const ManagedAuthContext = ({ children, pageProps }: ContextProps) => (
  <AuthProvider pageProps={pageProps}>{children}</AuthProvider>
)
