import { colors } from '@core/helpers'

import { MuiTypography } from '../typography'

export const MuiButtonBaseCustom = {
  defaultProps: {
    disableRipple: true
  }
}

export const MuiButtonCustom = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained'
  },
  styleOverrides: {
    sizeLarge: {
      ...MuiTypography.button_large,
      padding: '1.8rem 3.6rem',
      fontFamily: 'Manrope',
      height: '5.6rem'
    },
    sizeMedium: {
      ...MuiTypography.button_medium,
      padding: '1.5rem 3.2rem',
      height: '4.8rem',
      fontFamily: 'Manrope'
    },
    sizeSmall: {
      ...MuiTypography.button_small,
      padding: '1.2rem 2.65rem',
      height: '4rem',
      fontFamily: 'Manrope'
    },
    root: {
      borderRadius: '50rem',
      textAlign: 'center'
    },
    containedPrimary: {
      color: 'var(--color-white)',
      backgroundColor: colors.primary_button_active,
      border: `1px solid ${colors.primary_button_active}`,
      '&:hover': {
        backgroundColor: 'var(--color-primary)',
        border: '1px solid var(--color-primary)'
      },
      '&.Mui-disabled': {
        backgroundColor: 'var(--color-disabled-bg)',
        border: '1px solid var(--color-disabled-bg)',
        color: 'var(--color-white)'
      }
    },
    textPrimary: {
      color: colors.primary_button_active,
      border: '1px solid transparent',
      '&:hover': {
        backgroundColor: 'var(--color-primary-100)',
        color: 'var(--color-primary)'
      },
      '&.Mui-disabled': {
        color: 'var(--color-disabled-bg)'
      }
    },
    outlinedPrimary: {
      borderColor: colors.primary_button_active,
      color: colors.primary_button_active,
      '&:hover': {
        borderColor: 'var(--color-primary)',
        color: 'var(--color-primary)'
      },
      '&.Mui-disabled': {
        borderColor: 'var(--color-disabled-bg)',
        color: 'var(--color-disabled-bg)'
      }
    }
  }
}
