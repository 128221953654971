import type { IconProps } from '@core/types/components'

export function ChevronRightIcon({
  className = 'icon',
  size = 24,
  color = 'currentColor'
}: IconProps) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.6703 11.2561C16.8578 11.4436 16.9632 11.698 16.9632 11.9632C16.9632 12.2284 16.8578 12.4827 16.6703 12.6703L9.70711 19.6334C9.31658 20.024 8.68342 20.024 8.29289 19.6334C7.90237 19.2429 7.90237 18.6098 8.29289 18.2192L14.549 11.9632L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
