import { type IconProps } from '@core/types/components'

export const ClockIcon = ({ className, color = 'var(--color-primary)', size = 24 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/clock'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99967 2.66634C5.05416 2.66634 2.66634 5.05416 2.66634 7.99967C2.66634 10.9452 5.05416 13.333 7.99967 13.333C10.9452 13.333 13.333 10.9452 13.333 7.99967C13.333 5.05416 10.9452 2.66634 7.99967 2.66634ZM1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967ZM7.99967 4.66634C8.36786 4.66634 8.66634 4.96482 8.66634 5.33301V7.72353L10.4711 9.52827C10.7314 9.78862 10.7314 10.2107 10.4711 10.4711C10.2107 10.7314 9.78862 10.7314 9.52827 10.4711L7.52827 8.47108C7.40325 8.34606 7.33301 8.17649 7.33301 7.99967V5.33301C7.33301 4.96482 7.63148 4.66634 7.99967 4.66634Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
