import { type IconProps } from '@core/types/components'

export const CamIcon = ({ className, color = 'var(--color-primary)', size = 20 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 22 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.83301 7.83301C1.83301 6.31422 3.06423 5.08301 4.58301 5.08301H11.9163C13.4351 5.08301 14.6663 6.31422 14.6663 7.83301V8.18314L17.5131 6.75976C18.7321 6.15026 20.1663 7.03667 20.1663 8.39954V14.5998C20.1663 15.9627 18.7321 16.8491 17.5131 16.2396L14.6663 14.8162V15.1663C14.6663 16.6851 13.4351 17.9163 11.9163 17.9163H4.58301C3.06423 17.9163 1.83301 16.6851 1.83301 15.1663V7.83301ZM12.833 13.3131C12.8327 13.3259 12.8327 13.3387 12.833 13.3514V15.1663C12.833 15.6726 12.4226 16.083 11.9163 16.083H4.58301C4.07675 16.083 3.66634 15.6726 3.66634 15.1663V7.83301C3.66634 7.32675 4.07675 6.91634 4.58301 6.91634H11.9163C12.4226 6.91634 12.833 7.32675 12.833 7.83301V9.6479C12.8327 9.66065 12.8327 9.67342 12.833 9.6862V13.3131ZM14.6663 12.7665L18.333 14.5998L18.333 8.39954L14.6663 10.2329V12.7665Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
