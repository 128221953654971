import { type IconProps } from '@core/types/components'

export const UserIcon = ({ className, color = 'var(--color-primary)', size = 24 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/user'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.00033 2.66634C6.89576 2.66634 6.00033 3.56177 6.00033 4.66634C6.00033 5.77091 6.89576 6.66634 8.00033 6.66634C9.10489 6.66634 10.0003 5.77091 10.0003 4.66634C10.0003 3.56177 9.10489 2.66634 8.00033 2.66634ZM4.66699 4.66634C4.66699 2.82539 6.15938 1.33301 8.00033 1.33301C9.84127 1.33301 11.3337 2.82539 11.3337 4.66634C11.3337 6.50729 9.84127 7.99967 8.00033 7.99967C6.15938 7.99967 4.66699 6.50729 4.66699 4.66634ZM4.05563 13.333H11.945C11.6276 11.4412 9.98232 9.99967 8.00033 9.99967C6.01833 9.99967 4.37302 11.4412 4.05563 13.333ZM2.66699 13.9997C2.66699 11.0542 5.05481 8.66634 8.00033 8.66634C10.9458 8.66634 13.3337 11.0542 13.3337 13.9997C13.3337 14.3679 13.0352 14.6663 12.667 14.6663H3.33366C2.96547 14.6663 2.66699 14.3679 2.66699 13.9997Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
