import type { AppProps } from 'next/app'
import Script from 'next/script'
import { ReCaptchaProvider } from 'next-recaptcha-v3'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import { BaseHead } from '@core/components/common'
import { ManagedAuthContext } from '@core/contexts/auth/context'
import { ManagedCatalogContext } from '@core/contexts/catalog/context'
import { ManagedLayoutContext } from '@core/contexts/layout'
import { ManagedNotificationContext } from '@core/contexts/notification/context'
import createEmotionCache from '@core/lib/mui/createEmotionCache'
import theme from '@core/lib/mui/theme'
import { GTM_ID, RECATCHA_SITE_KEY } from '@core/lib/utils'
import { type PageProps } from '@core/types/components'
import { type EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { useGtm, useVh } from '@hooks/common'

import '@styles/app.scss'

// Client-side cache, shared for the whole session of the user in the browser.
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? '')
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  pageProps: PageProps
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  useVh()
  useGtm()

  return (
    <ReCaptchaProvider reCaptchaKey={RECATCHA_SITE_KEY}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <BaseHead />
          <CssBaseline />
          <ManagedAuthContext pageProps={pageProps}>
            <ManagedLayoutContext pageProps={pageProps}>
              <ManagedNotificationContext>
                <ManagedCatalogContext pageProps={pageProps}>
                  <Script
                    id='gtag-base'
                    strategy='afterInteractive'
                    dangerouslySetInnerHTML={{
                      __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `
                    }}
                  />
                  <Component {...pageProps} />
                </ManagedCatalogContext>
              </ManagedNotificationContext>
            </ManagedLayoutContext>
          </ManagedAuthContext>
        </ThemeProvider>
      </CacheProvider>
    </ReCaptchaProvider>
  )
}

export default MyApp
