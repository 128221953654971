import { type IconProps } from '@core/types/components'

export const SearchIcon = ({ className, color = 'var(--color-primary)', size = 16 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19 19L13 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  )
}
