import { type IconProps } from '@core/types/components'

export const SecurityOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 30
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V10H18C19.6569 10 21 11.3431 21 13V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V13C3 11.3431 4.34315 10 6 10H7V7ZM9 10H15V7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7V10ZM6 12C5.44772 12 5 12.4477 5 13V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V13C19 12.4477 18.5523 12 18 12H6ZM12 14C12.5523 14 13 14.4477 13 15V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V15C11 14.4477 11.4477 14 12 14Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
