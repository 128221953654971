import type { FetcherOptions } from '@core/types/api'
import { isEmptyValue } from '@lib/utils'

import httpClient from './httpClient'

export async function fetcher<T>({
  url,
  headers = {},
  params = {},
  ...restConfig
}: FetcherOptions): Promise<T> {
  if (isEmptyValue(url)) {
    throw new Error('The url parameter is required')
  }

  return await httpClient.get<T>(url, { headers, params, ...restConfig }).then(res => res.data)
}

export async function post<T>({ url, headers = {}, params = {} }: FetcherOptions): Promise<T> {
  if (isEmptyValue(url)) {
    throw new Error('The url parameter is required')
  }

  return await httpClient.post<T>(url, params, { headers }).then(res => res.data)
}

export async function fetcherNative<T>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<T> {
  return await fetch(input, init).then<T>(res => res.json())
}
