import type { IconProps } from '@core/types/components'

export function ArrowDropDownIcon({
  className = 'icon',
  size = 24,
  color = 'currentColor'
}: IconProps) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.48041 11.4814C7.7668 11.195 8.23112 11.195 8.5175 11.4814L15.999 18.9628L23.4804 11.4814C23.7668 11.195 24.2311 11.195 24.5175 11.4814C24.8039 11.7678 24.8039 12.2321 24.5175 12.5185L16.5175 20.5185C16.2311 20.8049 15.7668 20.8049 15.4804 20.5185L7.48041 12.5185C7.19403 12.2321 7.19403 11.7678 7.48041 11.4814Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
