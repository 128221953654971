import { type IconProps } from '@core/types/components'

export const TowerOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.33268 4.16602C3.33268 2.7853 4.45197 1.66602 5.83268 1.66602H14.166C15.5467 1.66602 16.666 2.7853 16.666 4.16602V16.666L17.4993 16.666C17.9596 16.666 18.3327 17.0391 18.3327 17.4993C18.3327 17.9596 17.9596 18.3327 17.4994 18.3327H2.49934C2.0391 18.3327 1.66601 17.9596 1.66602 17.4993C1.66602 17.0391 2.03912 16.666 2.49936 16.666L3.33268 16.666V4.16602ZM4.99935 16.666H7.49935V13.3327C7.49935 12.4122 8.24554 11.666 9.16602 11.666H10.8327C11.7532 11.666 12.4993 12.4122 12.4993 13.3327V16.666H14.9993V4.16602C14.9993 3.70578 14.6263 3.33268 14.166 3.33268H5.83268C5.37245 3.33268 4.99935 3.70578 4.99935 4.16602V16.666ZM10.8327 16.666V13.3327H9.16602V16.666H10.8327ZM6.66602 5.83266C6.66602 5.37243 7.03911 4.99933 7.49935 4.99933H8.33268C8.79292 4.99933 9.16602 5.37243 9.16602 5.83266C9.16602 6.2929 8.79292 6.666 8.33268 6.666H7.49935C7.03911 6.666 6.66602 6.2929 6.66602 5.83266ZM10.8327 5.83266C10.8327 5.37243 11.2058 4.99933 11.666 4.99933H12.4993C12.9596 4.99933 13.3327 5.37243 13.3327 5.83266C13.3327 6.2929 12.9596 6.666 12.4993 6.666H11.666C11.2058 6.666 10.8327 6.2929 10.8327 5.83266ZM6.66602 9.166C6.66602 8.70576 7.03911 8.33266 7.49935 8.33266H8.33268C8.79292 8.33266 9.16602 8.70576 9.16602 9.166C9.16602 9.62623 8.79292 9.99933 8.33268 9.99933H7.49935C7.03911 9.99933 6.66602 9.62623 6.66602 9.166ZM10.8327 9.166C10.8327 8.70576 11.2058 8.33266 11.666 8.33266H12.4993C12.9596 8.33266 13.3327 8.70576 13.3327 9.166C13.3327 9.62623 12.9596 9.99933 12.4993 9.99933H11.666C11.2058 9.99933 10.8327 9.62623 10.8327 9.166Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
