import { type IconProps } from '@core/types/components'

export const WarningIcon2 = ({
  className,
  color = 'var(--color-warning)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 81 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M72.5 40C72.5 57.6731 58.1731 72 40.5 72C22.8269 72 8.5 57.6731 8.5 40C8.5 22.3269 22.8269 8 40.5 8C58.1731 8 72.5 22.3269 72.5 40ZM44.5 56C44.5 58.2091 42.7091 60 40.5 60C38.2909 60 36.5 58.2091 36.5 56C36.5 53.7909 38.2909 52 40.5 52C42.7091 52 44.5 53.7909 44.5 56ZM40.5 20C38.2909 20 36.5 21.7909 36.5 24V40C36.5 42.2091 38.2909 44 40.5 44C42.7091 44 44.5 42.2091 44.5 40V24C44.5 21.7909 42.7091 20 40.5 20Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
