export const MuiBreackpoints = {
  min: 0,
  phone_xs: 320,
  phone_sm: 420,
  phone_md: 528,
  phone_lg: 648,
  phone_xl: 768,
  tablet_xs: 832,
  tablet_sm: 896,
  tablet_md: 960,
  tablet_lg: 1024,
  tablet_xl: 1200,
  desktop_xs: 1280,
  desktop_sm: 1366,
  desktop_md: 1440,
  desktop_lg: 1680,
  desktop_xl: 1920
}
