import { type IconProps } from '@core/types/components'

export const EmailOpenOutlinedIcon = ({
  className,
  color = 'var(--color-primary-darkest)',
  size = 56
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 56 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M48.3575 14.6442L29.7325 6.04807C28.6357 5.54184 27.3721 5.54184 26.2753 6.04807L7.65029 14.6442C6.18942 15.3185 5.25391 16.7806 5.25391 18.3896V43.1252C5.25391 45.4034 7.10073 47.2502 9.37891 47.2502H46.6289C48.9071 47.2502 50.7539 45.4034 50.7539 43.1252V18.3896C50.7539 16.7806 49.8184 15.3185 48.3575 14.6442Z'
          stroke={color}
          strokeWidth='4.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.25391 20.9998L5.25399 18.389C5.25404 16.7801 6.18954 15.318 7.65037 14.6438L26.2755 6.04758C27.3723 5.54136 28.6359 5.54136 29.7327 6.04758L48.3576 14.6437C49.8185 15.318 50.754 16.7801 50.754 18.3892L50.7539 20.9998L28.004 33.2498L5.25391 20.9998Z'
          stroke={color}
          strokeWidth='4.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  )
}
