import { type IconProps } from '@core/types/components'

export const CashOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.875 15.625C6.32134 15.6214 10.7483 16.2102 15.0392 17.3758C15.645 17.5408 16.25 17.0908 16.25 16.4625V15.625M3.125 3.75V4.375C3.125 4.54076 3.05915 4.69973 2.94194 4.81694C2.82473 4.93415 2.66576 5 2.5 5H1.875M1.875 5V4.6875C1.875 4.17 2.295 3.75 2.8125 3.75H16.875M1.875 5V12.5M16.875 3.75V4.375C16.875 4.72 17.155 5 17.5 5H18.125M16.875 3.75H17.1875C17.705 3.75 18.125 4.17 18.125 4.6875V12.8125C18.125 13.33 17.705 13.75 17.1875 13.75H16.875M1.875 12.5V12.8125C1.875 13.0611 1.97377 13.2996 2.14959 13.4754C2.3254 13.6512 2.56386 13.75 2.8125 13.75H3.125M1.875 12.5H2.5C2.66576 12.5 2.82473 12.5658 2.94194 12.6831C3.05915 12.8003 3.125 12.9592 3.125 13.125V13.75M16.875 13.75V13.125C16.875 12.9592 16.9408 12.8003 17.0581 12.6831C17.1753 12.5658 17.3342 12.5 17.5 12.5H18.125M16.875 13.75H3.125M12.5 8.75C12.5 9.41304 12.2366 10.0489 11.7678 10.5178C11.2989 10.9866 10.663 11.25 10 11.25C9.33696 11.25 8.70107 10.9866 8.23223 10.5178C7.76339 10.0489 7.5 9.41304 7.5 8.75C7.5 8.08696 7.76339 7.45107 8.23223 6.98223C8.70107 6.51339 9.33696 6.25 10 6.25C10.663 6.25 11.2989 6.51339 11.7678 6.98223C12.2366 7.45107 12.5 8.08696 12.5 8.75ZM15 8.75H15.0067V8.75667H15V8.75ZM5 8.75H5.00667V8.75667H5V8.75Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </i>
  )
}
