import { forwardRef } from 'react'
import { type Control, type FieldValues, useController } from 'react-hook-form'

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import type { SelectProps } from '@mui/material/Select'
import { ArrowDropDownIcon } from '@core/components/icons'
import { type DocumentType } from '@core/types/parameters'

type Props = SelectProps & {
  className?: string
  name: string
  control: any
  options: DocumentType[]
}

const DocumentTypes = forwardRef(({ className, name, control, options, ...props }: Props, ref) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control: control as Control<FieldValues> })

  const isError = error !== undefined

  const IconComponent: React.ElementType = ({ className }) => (
    <ArrowDropDownIcon className={className} color='#9799a1' />
  )

  return (
    <FormControl className={className} variant='filled' fullWidth>
      <InputLabel id='document-types-label'>{props?.label}</InputLabel>
      <Select
        {...field}
        ref={ref}
        labelId='document-types-label'
        label={props?.label}
        aria-describedby='document-types-helper-text'
        error={isError}
        IconComponent={IconComponent}
        disableUnderline
        {...props}
        onChange={(e, child) => {
          field.onChange(e)

          if (props?.onChange) {
            props.onChange(e, child)
          }
        }}
      >
        {options.map(item => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id='document-types-helper-text' error={isError}>
        {error?.message}
      </FormHelperText>
    </FormControl>
  )
})

DocumentTypes.displayName = 'DocumentTypes'

export default DocumentTypes
