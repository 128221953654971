import { type IconProps } from '@core/types/components'

export const VideoCameraIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/video-camera'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.83301 7.33301C1.83301 5.81422 3.06422 4.58301 4.58301 4.58301H11.9163C13.4351 4.58301 14.6663 5.81422 14.6663 7.33301V7.68314L17.5131 6.25976C18.7321 5.65026 20.1663 6.53667 20.1663 7.89954V14.0998C20.1663 15.4627 18.7321 16.3491 17.5131 15.7396L14.6663 14.3162V14.6663C14.6663 16.1851 13.4351 17.4163 11.9163 17.4163H4.58301C3.06423 17.4163 1.83301 16.1851 1.83301 14.6663V7.33301ZM12.833 12.8131C12.8327 12.8259 12.8327 12.8387 12.833 12.8514V14.6663C12.833 15.1726 12.4226 15.583 11.9163 15.583H4.58301C4.07675 15.583 3.66634 15.1726 3.66634 14.6663V7.33301C3.66634 6.82675 4.07675 6.41634 4.58301 6.41634H11.9163C12.4226 6.41634 12.833 6.82675 12.833 7.33301V9.1479C12.8327 9.16065 12.8327 9.17342 12.833 9.1862V12.8131ZM14.6663 12.2665L18.333 14.0998L18.333 7.89954L14.6663 9.73287V12.2665Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
