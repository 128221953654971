import { type IconProps } from '@core/types/components'

export const ArrowLeftIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 20
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 19 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.54264 0.921274C8.90793 1.2718 8.9199 1.85207 8.56938 2.21736L3.87279 7.11177H17.3339C17.8402 7.11177 18.2506 7.52218 18.2506 8.02844C18.2506 8.5347 17.8402 8.94511 17.3339 8.94511H3.92862L8.56938 13.7813C8.9199 14.1466 8.90793 14.7269 8.54264 15.0774C8.17736 15.4279 7.59708 15.416 7.24656 15.0507L1.08924 8.63403C0.748899 8.27935 0.748899 7.71935 1.08924 7.36467L7.24656 0.948007C7.59708 0.58272 8.17736 0.570751 8.54264 0.921274Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
