import { type IconProps } from '@core/types/components'

export const RadioButtonUnChecked = ({
  className,
  color = 'var(--color-neutral-gray-06',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='12' cy='12' r='11.5' stroke={color} />
      </svg>
    </i>
  )
}
