import { type TypographyOptions } from '@mui/material/styles/createTypography'

export const MuiTypography: TypographyOptions = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontFamily: 'Manrope',
  htmlFontSize: 10,
  d1: {
    fontSize: '5.2rem',
    fontWeight: 700,
    lineHeight: 1.2,
    display: 'block',
    letterSpacing: '-1%',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '6rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '7.2rem'
    }
  },
  d2: {
    fontWeight: 700,
    lineHeight: 1.2,
    display: 'block',
    letterSpacing: '-1%',
    fontSize: '4rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '5rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '6rem'
    }
  },
  d3: {
    fontWeight: 700,
    lineHeight: 1.2,
    display: 'block',
    letterSpacing: '-1%',
    fontSize: '2.8rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '3.6rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '4.8rem'
    }
  },
  h1: {
    lineHeight: 1.3,
    fontWeight: 500,
    fontSize: '2.8rem',
    display: 'block',
    letterSpacing: '-2%',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '3rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '3.2rem'
    }
  },
  h1_bold: {
    lineHeight: 1.3,
    fontWeight: 700,
    fontSize: '2.8rem',
    display: 'block',
    letterSpacing: '-2%',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '3rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '3.2rem'
    }
  },
  h2: {
    fontWeight: 500,
    lineHeight: 1.3,
    letterSpacing: '-2%',
    fontSize: '2.4rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '2.6rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2.8rem'
    }
  },
  h2_bold: {
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: '-2%',
    display: 'block',
    fontSize: '2.4rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '2.6rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2.8rem'
    }
  },
  h3: {
    fontWeight: 500,
    lineHeight: 1.3,
    letterSpacing: '-2%',
    fontSize: '2rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '2.2rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2.4rem'
    }
  },
  h3_bold: {
    fontWeight: 700,
    lineHeight: 1.3,
    display: 'block',
    letterSpacing: '-2%',
    fontSize: '2rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '2.2rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2.4rem'
    }
  },
  h4: {
    fontWeight: 500,
    lineHeight: 1.3,
    display: 'block',
    letterSpacing: '-2%',
    fontSize: '1.6rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '1.8rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2rem'
    }
  },
  h4_bold: {
    fontWeight: 700,
    lineHeight: 1.3,
    display: 'block',
    letterSpacing: '-2%',
    fontSize: '1.6rem',
    '@media (min-width: 769px) and (max-width: 1279px)': {
      fontSize: '1.8rem'
    },
    '@media (min-width: 1280px)': {
      fontSize: '2rem'
    }
  },
  body1: {
    fontSize: '1.8rem',
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b1)'
  },
  body1_medium: {
    fontSize: '1.8rem',
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b1)'
  },
  body1_semi_bold: {
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b1)'
  },
  body2: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b2)'
  },
  body2_bold: {
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b2)'
  },
  body2_medium: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b2)'
  },
  body2_semi_bold: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'var(--color-text-body-b2)'
  },
  body3: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: 1.5
  },
  body3_medium: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: 1.5
  },
  body3_semi_bold: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.5
  },
  body3_bold: {
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: 1.5
  },
  button_large: {
    display: 'block',
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: 1,
    letterSpacing: '-1%'
  },
  button: {
    display: 'block',
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: 1,
    textTransform: 'none',
    letterSpacing: '-1%'
  },
  button_medium: {
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: 1,
    letterSpacing: '-1%'
  },
  button_small: {
    display: 'block',
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: 1,
    letterSpacing: '-1%'
  },
  link_large: {
    fontWeight: 500,
    fontSize: '1.8rem',
    lineHeight: 1.55
  },
  link_medium: {
    fontWeight: 500,
    fontSize: '1.6rem',
    lineHeight: 1.5
  },
  link_small: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: 1.6
  },
  label1: {
    display: 'inline',
    fontWeight: 400,
    fontSize: '1.2rem',
    lineHeight: 1.5
  },
  label1_semi_bold: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.5
  },
  label1_light_bold: {
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: 1.5
  },
  label2: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5
  },
  label2_semi_bold: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5
  }
}
