import { createContext, useContext, useEffect } from 'react'

import isEmpty from 'just-is-empty'

import { capitalize } from '@mui/material'
import { capitalizeAll } from '@core/helpers/text'
import useCatalogStore, { type Center } from '@core/stores/catalog/useCatalogStore'
import { type CatalogProviderPageProps } from '@core/types/components'
import { type ContextProps } from '@core/types/context'

const CatalogContext = createContext({})

CatalogContext.displayName = 'CatalogContext'

export const initialCatalogPageProps: Record<keyof CatalogProviderPageProps, any[] | null> = {
  centersCatalog: null,
  consultTypeCatalog: null,
  documentTypesCatalog: null,
  relationsCatalog: null,
  sexsCatalog: null,
  guarantorsCatalog: null
}

export const CatalogProvider = ({ pageProps, ...props }: ContextProps) => {
  const relations = pageProps?.catalog?.relationsCatalog
  const documentTypes = pageProps?.catalog?.documentTypesCatalog
  const genders = pageProps?.catalog?.sexsCatalog
  const centers = pageProps?.catalog?.centersCatalog
  const sures = pageProps?.catalog?.guarantorsCatalog

  const {
    setCentersCatalog,
    setRelationsCatalog,
    setDocumentTypesCatalog,
    setSexsCatalog,
    setGuarantorsCatalog
  } = useCatalogStore()

  useEffect(() => {
    if (!centers) return
    const centersFormatted: Center[] = centers.map(data => ({
      ...data,
      name: capitalizeAll(data.name.toLowerCase())
    }))

    setCentersCatalog(centersFormatted)
  }, [centers])

  useEffect(() => {
    if (!genders) return
    setSexsCatalog(
      genders.map(gender => ({ ...gender, name: capitalize(gender.name.toLowerCase()) }))
    )
  }, [genders])

  useEffect(() => {
    if (!documentTypes) return
    setDocumentTypesCatalog(documentTypes)
  }, [documentTypes])

  useEffect(() => {
    if (!relations) return
    setRelationsCatalog(
      relations.map(relation => ({ ...relation, name: capitalize(relation.name.toLowerCase()) }))
    )
  }, [relations])

  useEffect(() => {
    if (!sures) return
    setGuarantorsCatalog(sures)
  }, [sures])

  return <CatalogContext.Provider value={{}} {...props} />
}

export const useCatalogProvider = (): {} => {
  const context = useContext(CatalogContext)

  if (isEmpty(context)) {
    throw new Error('useCatalogProvider must be used within a LayoutProvider')
  }

  return context
}

export const ManagedCatalogContext = ({ children, pageProps }: ContextProps) => (
  <CatalogProvider pageProps={pageProps}>{children}</CatalogProvider>
)
