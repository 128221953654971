import type { IconProps } from '@core/types/components'

export function ExclamationIcon({
  className = 'icon',
  size = 24,
  color = 'currentColor'
}: IconProps) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Icon/Outline/exclamation-circle'>
          <path
            id='Icon (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99967 2.66634C5.05416 2.66634 2.66634 5.05416 2.66634 7.99967C2.66634 10.9452 5.05416 13.333 7.99967 13.333C10.9452 13.333 13.333 10.9452 13.333 7.99967C13.333 5.05416 10.9452 2.66634 7.99967 2.66634ZM1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967ZM7.99967 4.66634C8.36786 4.66634 8.66634 4.96482 8.66634 5.33301V7.99967C8.66634 8.36786 8.36786 8.66634 7.99967 8.66634C7.63148 8.66634 7.33301 8.36786 7.33301 7.99967V5.33301C7.33301 4.96482 7.63148 4.66634 7.99967 4.66634ZM7.33301 10.6663C7.33301 10.2982 7.63148 9.99967 7.99967 9.99967H8.00634C8.37453 9.99967 8.67301 10.2982 8.67301 10.6663C8.67301 11.0345 8.37453 11.333 8.00634 11.333H7.99967C7.63148 11.333 7.33301 11.0345 7.33301 10.6663Z'
            fill={color}
          />
        </g>
      </svg>
    </i>
  )
}
