import { type IconProps } from '@core/types/components'

export const CreditCardIcon = ({
  className,
  color = 'var(--color-neutral-gray-07)',
  height = 27,
  width = 22
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 27 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.166504 5.66665C0.166504 2.72113 2.55432 0.333313 5.49984 0.333313H21.4998C24.4454 0.333313 26.8332 2.72113 26.8332 5.66665V16.3333C26.8332 19.2788 24.4454 21.6666 21.4998 21.6666H5.49984C2.55432 21.6666 0.166504 19.2788 0.166504 16.3333V5.66665ZM2.83317 9.66665V16.3333C2.83317 17.8061 4.02708 19 5.49984 19H21.4998C22.9726 19 24.1665 17.8061 24.1665 16.3333V9.66665H2.83317ZM24.1665 6.99998H2.83317V5.66665C2.83317 4.19389 4.02708 2.99998 5.49984 2.99998H21.4998C22.9726 2.99998 24.1665 4.19389 24.1665 5.66665V6.99998ZM5.49984 15C5.49984 14.2636 6.09679 13.6666 6.83317 13.6666H8.1665C8.90288 13.6666 9.49984 14.2636 9.49984 15C9.49984 15.7364 8.90288 16.3333 8.1665 16.3333H6.83317C6.09679 16.3333 5.49984 15.7364 5.49984 15ZM12.1665 15C12.1665 14.2636 12.7635 13.6666 13.4998 13.6666H14.8332C15.5696 13.6666 16.1665 14.2636 16.1665 15C16.1665 15.7364 15.5696 16.3333 14.8332 16.3333H13.4998C12.7635 16.3333 12.1665 15.7364 12.1665 15Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
