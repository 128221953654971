import type { IconProps } from '@core/types/components'

export function FolderDownloadIcon({
  className = 'icon',
  size = 24,
  color = 'var(--color-primary)'
}: IconProps) {
  return (
    <i style={{ width: size, height: size }} className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 80 80'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 16C11.5817 16 8 19.5817 8 24V56C8 60.4183 11.5817 64 16 64H64C68.4183 64 72 60.4183 72 56V32C72 27.5817 68.4183 24 64 24H44L36 16H16ZM44 36C44 33.7909 42.2091 32 40 32C37.7909 32 36 33.7909 36 36V42.3431L34.8284 41.1716C33.2663 39.6095 30.7337 39.6095 29.1716 41.1716C27.6095 42.7337 27.6095 45.2663 29.1716 46.8284L37.1716 54.8284C38.7337 56.3905 41.2663 56.3905 42.8284 54.8284L50.8284 46.8284C52.3905 45.2663 52.3905 42.7337 50.8284 41.1716C49.2663 39.6095 46.7337 39.6095 45.1716 41.1716L44 42.3431V36Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
