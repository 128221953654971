/* eslint-disable @typescript-eslint/no-empty-function */

import { type ScrollToTopProps } from './types'

export const isBrowser = typeof window !== 'undefined'

export const isNavigator = typeof navigator !== 'undefined'

export const noop = (): void => {}

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  if (obj?.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>))
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  if (obj?.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>))
  }
}

/**
 * Prevent default event
 * @param {Event} e
 */
export function preventDefault(e: React.SyntheticEvent): boolean {
  e.preventDefault()

  return false
}

/**
 * Validate string or number if it is empty
 * @param {*} val
 * @returns {boolean}
 */
export function isEmptyValue(val: unknown): boolean {
  if (val == null) {
    return true
  }

  if (typeof val === 'string' && val.trim() === '') {
    return true
  }

  if (typeof val === 'number' && isNaN(val)) {
    return true
  }

  return false
}

/**
 * Scroll to top depending on the element top position
 * @param {ScrollToTopProps} props
 * @returns {void}
 */
export function scrollToTop({ element, offset }: ScrollToTopProps): void {
  const elementTop = element?.getBoundingClientRect()?.top ?? 0
  const bodyTop = document.body.getBoundingClientRect()?.top ?? 0
  const top = elementTop - bodyTop - offset

  if (isBrowser && elementTop < 0) {
    window.scrollTo({ top, behavior: 'smooth' })
  }
}

/**
 * Number format to currency
 * @param {any} value
 * @param {string} currency
 * @returns {string}
 */
export function formatCurrency(value: unknown, currency = 'PEN'): string {
  // Check if Intl.NumberFormat is supported
  if (typeof Intl === 'undefined' || !Intl.NumberFormat.supportedLocalesOf('es-PE').length) {
    return `S/ ${value as string}`
  }

  const formatter = new Intl.NumberFormat('es-PE', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(value as number)
}
