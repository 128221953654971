import { SEO } from '@core/components/common'
import { type SEOMeta } from '@core/types/seo'
import { getSEOConfig } from '@config/seo-meta'

type BaseHeadProps = {
  seo?: SEOMeta
}

/**
 */
export default function BaseHead({ seo }: BaseHeadProps) {
  return (
    <SEO config={getSEOConfig(seo)}>
      {/* <link
        href=''
        rel='preload'
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      /> */}
    </SEO>
  )
}
