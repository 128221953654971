import { type IconProps } from '@core/types/components'

export const UbiIcon = ({ className, color = 'var(--color-primary)', size = 20 }: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.00033 1.33333C4.68662 1.33333 2.00033 4.01962 2.00033 7.33333C2.00033 7.90626 2.20715 8.56979 2.59444 9.29604C2.97874 10.0167 3.51763 10.7562 4.12509 11.469C5.34022 12.8949 6.77794 14.154 7.6447 14.8669C7.85521 15.0401 8.14545 15.0401 8.35595 14.8669C9.22271 14.154 10.6604 12.8949 11.8756 11.469C12.483 10.7562 13.0219 10.0167 13.4062 9.29604C13.7935 8.56979 14.0003 7.90626 14.0003 7.33333C14.0003 4.01962 11.314 1.33333 8.00033 1.33333ZM0.666992 7.33333C0.666992 3.28325 3.95024 0 8.00033 0C12.0504 0 15.3337 3.28325 15.3337 7.33333C15.3337 8.20907 15.0245 9.09506 14.5827 9.92343C14.138 10.7574 13.5349 11.5776 12.8904 12.3339C11.6016 13.8462 10.0955 15.1626 9.20292 15.8967C8.5003 16.4746 7.50034 16.4746 6.79773 15.8967C5.90516 15.1626 4.3991 13.8462 3.11028 12.3339C2.46577 11.5776 1.86265 10.7574 1.41793 9.92343C0.976192 9.09506 0.666992 8.20907 0.666992 7.33333ZM8.00033 6C7.26395 6 6.66699 6.59695 6.66699 7.33333C6.66699 8.06971 7.26395 8.66667 8.00033 8.66667C8.7367 8.66667 9.33366 8.06971 9.33366 7.33333C9.33366 6.59695 8.7367 6 8.00033 6ZM5.33366 7.33333C5.33366 5.86057 6.52757 4.66667 8.00033 4.66667C9.47308 4.66667 10.667 5.86057 10.667 7.33333C10.667 8.80609 9.47308 10 8.00033 10C6.52757 10 5.33366 8.80609 5.33366 7.33333Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
