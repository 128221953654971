import { type IconProps } from '@core/types/components'

export const CheckOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.4213 5.24408C16.7467 5.56951 16.7467 6.09715 16.4213 6.42259L8.08795 14.7559C7.76252 15.0814 7.23488 15.0814 6.90944 14.7559L3.57611 11.4226C3.25067 11.0972 3.25067 10.5695 3.57611 10.2441C3.90155 9.91864 4.42918 9.91864 4.75462 10.2441L7.4987 12.9882L15.2428 5.24408C15.5682 4.91864 16.0958 4.91864 16.4213 5.24408Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
