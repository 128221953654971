import { createTheme } from '@mui/material/styles'
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@core/components/icons/Common'
import { colors } from '@core/helpers'

import { RadioButtonChecked } from '../../components/icons/Common/RadioButtonChecked'
import { RadioButtonUnChecked } from '../../components/icons/Common/RadioButtonUnChecked'
import { MuiButtonBaseCustom, MuiButtonCustom } from './muiComponents/button'
import { MuiBreackpoints } from './breakpoints'
import { MuiPalette } from './palette'
import { MuiTypography } from './typography'

// Create a theme instance.
const defaultTheme = createTheme({})

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const theme = createTheme({
  breakpoints: {
    values: MuiBreackpoints
  },
  palette: MuiPalette,
  typography: MuiTypography,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'label1' },
          style: {
            color: colors.text_primary
          }
        }
      ]
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...MuiTypography.body3,
          fontWeight: 'var(--medium)',
          fontFamily: 'Manrope'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.5rem',
          color: colors.black
        },
        root: {
          color: 'var(--color-text-primary)',
          gap: '0.4rem',
          lineHeight: '1.4',
          '&.Mui-focused': {
            color: 'var(--color-text-primary)'
          },
          '&.Mui-disabled': {
            borderColor: '--var(--color-disabled-bg)'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope',
          alignSelf: 'flex-start',
          '&.Mui-error': {
            color: 'var(--color-neutral-400)'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: 500,
          lineHeight: 1.5,
          color: 'var(--color-text-body-b2)',
          fontFamily: 'Manrope',
          borderRadius: '0.8rem',
          backgroundColor: 'var(--color-white)',
          '@media (min-width: 1280px)': {
            fontSize: '1.6rem'
          },
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: 'var(--color-neutral-300)',
            '&::placeholder': {
              color: 'red'
            }
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-primary)'
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-primary)'
            }
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--color-error)'
            }
          },
          '& .MuiSelect-iconOutlined': {
            fontSize: '24px',
            right: '12px'
          },
          '&.Mui-disabled': {
            outlineColor: 'var(--color-disabled)',
            borderColor: 'var(--color-disabled)',
            '&:hover': {
              outlineColor: 'var(--color-disabled)'
            }
          }
        },
        input: {
          // Custom Input File
          '&[type="file" i]': {
            color: 'transparent',
            '&:not([value=""])': {
              color: 'var(--color-text-primary)'
            }
          },
          '&[type="file" i]::-webkit-file-upload-button': {
            display: 'none',
            visibility: 'hidden'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope',
          ...MuiTypography.body2_semi_bold
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: '20rem'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          '@media (min-width: 1280px)': {
            fontSize: '1.6rem'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              maxHeight: '360px',
              boxShadow: 'var(--shadow-neutral-5)'
            }
          }
        }
      },
      styleOverrides: {
        outlined: {
          fontWeight: 500,
          fontSize: '1.4rem',
          fontFamily: 'Manrope',
          color: colors.text_title,
          paddingTop: '1.35rem !important',
          paddingBottom: '1.35rem !important',
          marginLeft: '5px',
          '@media (min-width: 1280px)': {
            fontSize: '1.6rem',
            paddingTop: '1.2rem !important',
            paddingBottom: '1.2rem !important'
          }
        },
        iconStandard: {}
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        icon: <RadioButtonUnChecked size={24} />,
        checkedIcon: <RadioButtonChecked size={24} />
      },
      styleOverrides: {
        root: {
          color: 'var(--color-neutral-gray-06)',
          '& .MuiSvgIcon-root': {
            height: 30,
            width: 30
          },
          '&.Mui-checked': {
            color: 'var(--color-primary)'
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <CheckboxUncheckedIcon size={24} />,
        checkedIcon: <CheckboxCheckedIcon size={24} />
      },
      styleOverrides: {
        root: {
          color: 'var(--color-neutral-300)',
          borderRadius: '0.4rem',
          padding: '0',
          '& .MuiSvgIcon-root': {
            fontSize: '3.2rem'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '2rem'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          borderRadius: '16px',
          backgroundColor: 'var(--color-disabled-bg)',
          opacity: 1,
          transition: defaultTheme.transitions.create(['opacity', 'background-color'], {
            duration: defaultTheme.transitions.duration.shortest
          }),
          '&$checked': {
            backgroundColor: 'red'
          },
          '&.Mui-checked': {
            backgroundColor: 'red'
          }
        },
        thumb: {
          boxShadow: 'none',
          width: 20,
          color: 'var(--color-white)',
          height: 20,
          borderRadius: '50%'
        },
        switchBase: {
          position: 'absolute',
          top: 6,
          left: 6,
          zIndex: 1, // Render above the focus ripple.
          transition: defaultTheme.transitions.create(['left', 'transform'], {
            duration: defaultTheme.transitions.duration.shortest
          }),
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '&$checked': {
            transform: 'translateX(20px)',
            backgroundColor: 'transparent'
          }
        },
        root: {
          display: 'inline-flex',
          width: 34 + 12 * 3,
          height: 14 + 12 * 3,
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'relative',
          flexShrink: 0,
          zIndex: 0, // Reset the stacking context.
          verticalAlign: 'middle', // For correct alignment with the text.
          '@media print': {
            colorAdjust: 'exact'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        // popper: {
        //   // marginTop: '3.4rem !important',
        //   // transform: 'translate(2.5rem, 11.4rem) !important',
        //   '& .MuiAutocomplete-paper': {}
        // },
        listbox: {
          '& .MuiAutocomplete-option': {
            ...MuiTypography.body2,
            fontSize: '1.4rem',
            fontWeight: 400,
            fontFamily: 'Manrope',
            marginLeft: '5px',
            '@media (min-width: 1280px)': {
              fontSize: '1.6rem'
            },
            '&.Mui-selected': {
              backgroundColor: 'var(--color-primary)',
              color: 'var(--color-white)'
            },
            '&.Mui-focused': {
              backgroundColor: 'var(--color-primary-100)'
            }
          }
        },
        inputRoot: {
          display: 'flex',
          justifyItems: 'center'
        },
        endAdornment: {
          '& .MuiAutocomplete-clearIndicator': {
            color: 'var(--color-primary)'
          },
          '& .MuiAutocomplete-popupIndicator': {
            display: 'none'
          }
        },
        noOptions: {
          fontSize: '1.4rem',
          color: 'var(--color-text-secondary)'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-completed ': {
            color: 'var(--color-primary)'
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          color: 'var(--color-text-secondary)'
        }
      }
    },
    MuiButtonBase: MuiButtonBaseCustom,
    MuiButton: MuiButtonCustom as any,
    MuiLink: {
      defaultProps: {
        textAlign: 'left'
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: 'var(--color-primary)'
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined'
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: 1,
            borderRadius: 8,
            transition: 'all ease 0.3s',
            borderColor: 'var(--color-neutral-gray-02)',
            '&:hover': {
              backgroundColor: 'var(--color-primary-100)',
              transition: 'all ease 0.3s',
              borderWidth: 1,
              borderColor: 'var(--color-primary-100)'
            }
          }
        }
      ]
    }
  }
})

export default theme
