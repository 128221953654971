import type { IconProps } from '@core/types/components'

export function GoogleMapIcon({ className = 'icon', size = 24 }: IconProps) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.8798 13.1801H17.8848C17.8848 13.1801 15.9998 15.9451 14.4148 18.0001C13.0448 19.7751 12.6448 21.2951 12.5298 22.0301C12.4848 22.3001 12.2648 22.5001 11.9998 22.5001C11.7348 22.5001 11.5148 22.3001 11.4698 22.0301C11.3548 21.2951 10.9548 19.7751 9.58482 18.0001C9.37482 17.7251 9.15982 17.4401 8.94482 17.1501L14.1198 11.0001L18.2848 6.06006C18.7448 7.02506 18.9998 8.10506 18.9998 9.25006C18.9998 10.7001 18.5848 12.0451 17.8798 13.1801Z'
          fill='#48B564'
        />
        <path
          d='M14.1202 11L8.94521 17.15C7.53521 15.26 6.11521 13.18 6.11521 13.18H6.12021C5.97021 12.94 5.83521 12.695 5.72021 12.44L9.88022 7.5C9.48522 7.975 9.25022 8.585 9.25022 9.25C9.25022 10.77 10.4802 12 12.0002 12C12.8552 12 13.6202 11.61 14.1202 11Z'
          fill='#FCC60E'
        />
        <path
          d='M14.1998 2.37L9.91477 7.46L6.63477 4.6C7.91477 3.01 9.84477 2 11.9998 2C12.7698 2 13.5098 2.13 14.1998 2.37Z'
          fill='#2C85EB'
        />
        <path
          d='M9.915 7.4601L9.88 7.5001L5.72 12.4401C5.26 11.4751 5 10.3951 5 9.2501C5 7.4801 5.615 5.8551 6.635 4.6001L9.915 7.4601Z'
          fill='#ED5748'
        />
        <path
          d='M14.1199 11.0001C14.5149 10.5251 14.7499 9.91512 14.7499 9.25012C14.7499 7.73012 13.5199 6.50012 11.9999 6.50012C11.1449 6.50012 10.3799 6.89012 9.87988 7.50012L14.1999 2.37012C15.9949 2.98012 17.4649 4.32512 18.2849 6.06012L14.1199 11.0001Z'
          fill='#5695F6'
        />
      </svg>
    </i>
  )
}
