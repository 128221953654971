/* eslint-disable @typescript-eslint/indent */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import isEmpty from 'just-is-empty'

import { type ContextProps } from '@core/types/context'
import { type AppointmentNotification } from '@core/types/notifications'
import { useSaludProgramsNotificattions } from '@hooks/useSaludProgramsNotificattions'
import { getAppointmentNotifications } from '@services/notification'
import { type SaludProgram } from '@services/programs/types'

import { useAuth } from '../auth/context'
import { type NotificationContextValue } from './types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const NotificationContext = createContext<NotificationContextValue>({} as NotificationContextValue)

NotificationContext.displayName = 'NotificationContext'

export const NotificationProvider = ({ ...props }: ContextProps) => {
  const { user } = useAuth()
  const [programsExpired, setProgramsExpired] = useState<SaludProgram[]>([])
  const [appointmentNotifications, setAppointmentNotifications] = useState<
    AppointmentNotification[]
  >([])

  const {
    isLoading: isLoadingPrograms,
    onDeleteProgramNotification,
    programs
  } = useSaludProgramsNotificattions({ setProgramsExpired, programsExpired })

  useEffect(() => {
    if (!user) return
    getAppointmentNotifications()
      .then(notifications => notifications && setAppointmentNotifications(notifications.details))
      .catch(err => console.error(err))
  }, [user])

  const value = useMemo<NotificationContextValue>(
    () => ({
      isLoadingPrograms,
      programsExpired,
      onDeleteProgramNotification,
      programs,
      appointmentNotifications
    }),
    [
      isLoadingPrograms,
      programsExpired,
      appointmentNotifications,
      onDeleteProgramNotification,
      programs
    ]
  )

  return <NotificationContext.Provider value={value} {...props} />
}

export const useNotification = (): NotificationContextValue => {
  const context = useContext(NotificationContext)

  if (isEmpty(context)) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }

  return context
}

export const ManagedNotificationContext = ({ children }: ContextProps) => (
  <NotificationProvider>{children}</NotificationProvider>
)
