import { Fragment } from 'react'

import { type OpenGraphImage } from '@core/types/seo'

type OGImageProps = OpenGraphImage & {
  id: number
}

export default function OGImage({ id, url, width, height, alt }: OGImageProps) {
  return (
    <Fragment key={`og:image:${id}`}>
      <meta key={`og:image:url:${id}`} property='og:image' content={url} />
      <meta key={`og:image:width:${id}`} property='og:image:width' content={`${width}`} />
      <meta key={`og:image:height:${id}`} property='og:image:height' content={`${height}`} />
      <meta key={`og:image:alt:${id}`} property='og:image:alt' content={alt} />
    </Fragment>
  )
}
