import { SERVER_ERROR_MESSAGE } from '@core/lib/utils'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const interceptErrorHandler = (error: any): void => {
  if (error.code === 'ERR_NETWORK') {
    alert(SERVER_ERROR_MESSAGE)
  }

  if (error.code === 'ECONNABORTED') {
    alert('Error de timeout: La solicitud ha superado el tiempo máximo de espera.')
  }

  if (error.response && error.response.status === 0) {
    alert(
      'Error de CORS: La solicitud fue bloqueada por el navegador debido a la política de CORS.'
    )
  }
}
