/**
 * Error response status
 */
export const UNAUTHORIZED = 401

export const FORBIDDEN = 403

export const NOT_FOUND = 404

export const INTERNAL_SERVER_ERROR = 500

export const BAD_REQUEST = 400

/**
 * Regex constants
 */
export const EMAIL_REGEX = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/

export const LOWERCASE_REGEX = /[a-z]/

export const UPPERCASE_REGEX = /[A-Z]/

export const CONTAIN_DIGIT_REGEX = /\d/

export const CONTAIN_SPECIAL_CHARACTER_REGEX = /[!@#$%^&*?;.,]/

export const NUMERIC = 'numeric'

export const ALPHANUMERIC = 'alphanumeric'

export const NUMERIC_REGEX = /^[0-9]+$/

export const DECIMAL_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/

export const ALFA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/

export const ALPHABETIC_REGEX =
  /^[a-zA-ZÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖßÙÚÛÜÝŸÑàáâãäåçèéêëìíîïðòóôõöùúûüýÿñ\s]+$/

export const MIN_LENGTH_REQUIRED = 1

export const ALLOWED_KEYS = ['Backspace', 'Delete', 'Enter', 'Tab', 'ArrowLeft', 'ArrowRight']

export const WHASTAPP_NUMBER = '51918172291'

export const CONTACT_WHATSAPP_LINK = `https://api.whatsapp.com/send?phone=${WHASTAPP_NUMBER}`

export const BLUR_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN88vr5fwAJKAO32Y+nDwAAAABJRU5ErkJggg=='

export const PRIMARY_BLUR_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89P/rfwAJuAPnYkm9AwAAAABJRU5ErkJggg=='

export const AVIVA_LOGO_PATH = '/images/blog/logo_aviva.svg'
export const MINI_BANNER_PATH = '/images/banner/banner_acompañante.png'

export const MY_AVIVA_MAX_WIDTH = '120rem'

export const SERVER_ERROR_MESSAGE =
  'Hubo un inconveniente en la comunicación, por favor vuelva a intentarlo en 15 minutos'
