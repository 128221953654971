import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import isEmpty from 'just-is-empty'

import { MiniAlert } from '@core/components/ui/CustomDialog/MiniAlertError/MiniAlert'
import { defaultMediaParams, useMediaQuery } from '@core/hooks/common'
import useMiniAlertStore, { alertPropsSelector } from '@core/stores/miniAlert/useMiniAlertStore'
import { type MediaParams, type PageProps } from '@core/types/components'
import { type ContextProps, type ContextReducer, type ContextReducers } from '@core/types/context'
import { noop } from '@lib/utils'

import { type LayoutState } from './types'

const initialState: LayoutState = {
  isMobile: true,
  isTablet: false,
  isDesktop: false,

  isMobileSmall: true,
  isMobileMedium: false,
  isMobileLarge: false,

  isTabletSmall: false,
  isTabletMedium: false,
  isTabletLarge: false,

  isDesktopSmall: false,
  isDesktopMedium: false,
  isDesktopLarge: false,

  openSidebar: false,
  openNotification: false,
  toggleSidebar: noop,
  toggleNotification: noop,
  resetState: noop
}

const ACTIONS = {
  CHANGE_MEDIA: 'CHANGE_MEDIA',
  RESET_STATE: 'RESET_STATE',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION'
}

const ACTIONS_REDUCERS: ContextReducers<LayoutState> = {
  [ACTIONS.CHANGE_MEDIA]: (state, action) => ({
    ...state,
    ...(action.payload as MediaParams)
  }),
  [ACTIONS.TOGGLE_SIDEBAR]: state => ({ ...state, openSidebar: !state.openSidebar }),
  [ACTIONS.RESET_STATE]: state => ({
    ...state,
    ...initialState
  }),
  [ACTIONS.TOGGLE_NOTIFICATION]: state => ({
    ...state,
    openNotification: !state.openNotification
  })
}

const LayoutContext = createContext<LayoutState>(initialState)

LayoutContext.displayName = 'LayoutContext'

const layoutReducer: ContextReducer<LayoutState> = (state, action) => {
  const actionReducer = ACTIONS_REDUCERS[action.type]

  return typeof actionReducer === 'function' ? actionReducer(state, action) : state
}

const getInitialState = (pageProps: PageProps | undefined): LayoutState => {
  const { isMobile, isTablet, isDesktop } = pageProps?.media ?? defaultMediaParams

  return {
    ...initialState,
    ...(isMobile && {
      isMobile: true,
      isMobileSmall: true,
      isMobileMedium: true,
      isMobileLarge: true
    }),
    ...(isTablet && {
      isTablet: true,
      isTabletSmall: true,
      isTabletMedium: true,
      isTabletLarge: true
    }),
    ...(isDesktop && {
      isDesktop: true,
      isDesktopSmall: true,
      isDesktopMedium: true,
      isDesktopLarge: true
    })
  }
}

export const LayoutProvider = ({ pageProps, ...props }: ContextProps) => {
  const {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopLarge,
    isDesktopMedium,
    isDesktopSmall,
    isMobileLarge,
    isMobileMedium,
    isMobileSmall,
    isTabletLarge,
    isTabletMedium,
    isTabletSmall
  } = useMediaQuery(pageProps?.media ?? defaultMediaParams)

  const [state, dispatch] = useReducer(layoutReducer, getInitialState(pageProps ?? {}))

  // Update media state when media query changes
  useEffect(
    () => {
      if (
        isMobile !== state.isMobile ||
        isTablet !== state.isTablet ||
        isDesktop !== state.isDesktop ||
        isMobileSmall !== state.isMobileSmall ||
        isMobileMedium !== state.isMobileMedium ||
        isMobileLarge !== state.isMobileLarge ||
        isTabletSmall !== state.isTabletSmall ||
        isTabletMedium !== state.isTabletMedium ||
        isTabletLarge !== state.isTabletLarge ||
        isDesktopSmall !== state.isDesktopSmall ||
        isDesktopMedium !== state.isDesktopMedium ||
        isDesktopLarge !== state.isDesktopLarge
      ) {
        dispatch({
          type: ACTIONS.CHANGE_MEDIA,
          payload: {
            isMobile,
            isTablet,
            isDesktop,
            isDesktopLarge,
            isDesktopMedium,
            isDesktopSmall,
            isMobileLarge,
            isMobileMedium,
            isMobileSmall,
            isTabletLarge,
            isTabletMedium,
            isTabletSmall
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isMobile,
      isTablet,
      isDesktop,
      isDesktopLarge,
      isDesktopMedium,
      isDesktopSmall,
      isMobileLarge,
      isMobileMedium,
      isMobileSmall,
      isTabletLarge,
      isTabletMedium,
      isTabletSmall,
      dispatch
    ]
  )

  const toggleSidebar = useCallback(() => dispatch({ type: ACTIONS.TOGGLE_SIDEBAR }), [dispatch])

  const toggleNotification = useCallback(
    () => dispatch({ type: ACTIONS.TOGGLE_NOTIFICATION }),
    [dispatch]
  )

  const resetState = useCallback(() => dispatch({ type: ACTIONS.RESET_STATE }), [dispatch])

  const value = useMemo<LayoutState>(
    () => ({
      ...state,
      toggleSidebar,
      toggleNotification,
      resetState
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <LayoutContext.Provider value={value} {...props} />
}

export const useLayout = (): LayoutState => {
  const context = useContext(LayoutContext)

  if (isEmpty(context)) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }

  return context
}

export const ManagedLayoutContext = ({ children, pageProps }: ContextProps) => {
  const props = useMiniAlertStore(alertPropsSelector)

  return (
    <>
      {props.open && <MiniAlert {...props} />}
      <LayoutProvider pageProps={pageProps}>{children}</LayoutProvider>
    </>
  )
}
