import type { IconProps } from '@core/types/components'

export function ChevronDownIcon({
  className = 'icon',
  size = 24,
  color = 'var(--color-primary)'
}: IconProps) {
  return (
    <i style={{ width: size, height: size }} className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.1514 7.77531C20.542 8.16584 20.542 8.799 20.1514 9.18953L13.1883 16.1527C13.0007 16.3402 12.7464 16.4456 12.4812 16.4456C12.2159 16.4456 11.9616 16.3402 11.7741 16.1527L4.81089 9.18953C4.42036 8.799 4.42036 8.16584 4.81089 7.77531C5.20141 7.38479 5.83458 7.38479 6.2251 7.77531L12.4812 14.0314L18.7372 7.77532C19.1278 7.38479 19.7609 7.38479 20.1514 7.77531Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
