import { DateTime } from 'luxon'

import { type DateTimeRange, type DateTimeRangeValues } from '@core/types/components'

export const calcYearsOld = (fechaNacimiento: string | null): number => {
  if (!fechaNacimiento) return 0
  const currentDate = DateTime.now()
  const bornDate = DateTime.fromISO(fechaNacimiento)
  const yearsOld = currentDate.diff(bornDate, 'years').years

  return Math.floor(yearsOld)
}

export function getDateTimeRangeValues(value: DateTimeRange): DateTimeRangeValues {
  const [start, end] = value
  let startText = ''
  let endText = ''

  const startDate = ISOToLuxonDateTime(start)
  const endDate = ISOToLuxonDateTime(end)

  if (startDate) {
    const { weekdayShort, day, monthShort } = startDate.setLocale('es')

    startText = `${weekdayShort ?? ''}, ${day} ${monthShort ?? ''}`
  }

  if (endDate) {
    const { weekdayShort, day, monthShort } = endDate.setLocale('es')

    endText = `${weekdayShort ?? ''}, ${day} ${monthShort ?? ''}`
  }

  return {
    start,
    end,
    startText,
    endText
  }
}

export const ISOToLuxonDateTime = (ISODate: string | null | undefined): DateTime =>
  ISODate ? DateTime.fromISO(ISODate) : DateTime.now()

export const formatDateTimeToString = (dateTime: DateTime): string => {
  if (!dateTime) return '-'

  return dateTime.toFormat('yyyy-MM-dd')
}

export const convertHourToAMPM = (hour24h: string): string => {
  if (!hour24h) return ''
  // Obtener la hora y los minutos
  const [hour, minutes] = hour24h.split(':').map(Number)

  // Verificar si es "a.m." o "p.m."
  const dayShift = hour < 12 ? 'a.m.' : 'p.m.'

  // Convertir la hora a formato de 12 horas
  let hora12h = hour % 12
  hora12h = hora12h === 0 ? 12 : hora12h // Si es 0, lo cambiamos a 12

  // Construir la hora en formato "hh:mm a.m." o "hh:mm p.m."
  const horaAMPM = `${hora12h.toString().padStart(2, '0')}:${minutes
    ?.toString()
    .padStart(2, '0')} ${dayShift}`

  return horaAMPM
}

export const isSomeDate = (date1: DateTime, date2: DateTime): boolean => {
  return date1.startOf('day').equals(date2.startOf('day'))
}

export const datexday = (citaday: string): string => {
  const fechaParts = citaday.split('/')

  // Verificar si hay suficientes componentes para construir una fecha válida

  // Obtener componentes de la fecha
  const dia = parseInt(fechaParts[0], 10)
  const mes = parseInt(fechaParts[1], 10) - 1 // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
  const año = parseInt(fechaParts[2], 10)

  // Crear el objeto Date
  const fecha = new Date(año, mes, dia)

  // Días de la semana en español
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

  // Obtener el día de la semana
  const diaSemana = diasSemana[fecha.getDay()]

  return diaSemana
}

export const obtenerNumeroDia = (cadenaFecha: string): Number => {
  // Obtener el número del día (los primeros dos caracteres de la cadena)
  const numeroDiaString = cadenaFecha.substring(0, 2)

  // Convertir el número del día a un número entero
  const numeroDia = parseInt(numeroDiaString, 10)

  return numeroDia
}

export const obtenerNombreMes = (cadenaFecha: string): string => {
  // Obtener el fragmento correspondiente al mes (entre los caracteres 3 y 5)
  const numeroMesString = cadenaFecha.substring(3, 5)

  // Convertir el fragmento del número del mes a un número entero
  const numeroMes = parseInt(numeroMesString, 10)

  // Array de nombres de meses en español
  const nombresMeses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  // Obtener el nombre del mes utilizando el número del mes como índice en el array
  const nombreMes = nombresMeses[numeroMes - 1] // Restar 1 porque los meses en JavaScript van de 0 a 11

  return nombreMes
}
