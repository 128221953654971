import { type IconProps } from '@core/types/components'

export const PencilIcon = ({
  className,
  color = 'var(--color-primary-light)',
  size = 22
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 22 22'
        fill='none'
      >
        <path
          fill={color}
          fillRule='evenodd'
          d='M17.931 4.068a1.375 1.375 0 0 0-1.944 0l-.727.727 1.944 1.944.727-.726a1.375 1.375 0 0 0 0-1.945Zm1.297 3.241a3.208 3.208 0 0 0-4.538-4.537l-1.374 1.375L2.101 15.36a.916.916 0 0 0-.268.648v3.273c0 .507.41.917.917.917h3.208a.917.917 0 0 0 .648-.269l12.62-12.62Zm-3.32.727L13.964 6.09 3.666 16.387v1.978H5.58l10.329-10.33Z'
          clipRule='evenodd'
        />
      </svg>
    </i>
  )
}
