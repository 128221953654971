import axios from 'axios'
import { getCookie } from 'cookies-next'

import { interceptErrorHandler } from '@core/helpers/errorHandler'
import { getAvivaServerUrl } from '@core/lib/api'
import { isBrowser } from '@core/lib/utils'

const avivaUserService = axios.create({
  baseURL: getAvivaServerUrl(undefined, 'user')
})

const avivaAppointmentService = axios.create({
  baseURL: getAvivaServerUrl(undefined, 'appointment')
})

const alimServer = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ALIMDREAMS_SERVER ?? ''
})

avivaAppointmentService.interceptors.request.use(
  config => {
    // Get token from cookie if is browser and add to headers
    if (isBrowser) {
      const hasTokenHeader = config.headers.token
      if (hasTokenHeader) return config

      const access = getCookie('session')?.toString()
      if (access) config.headers.token = access
    }

    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

avivaUserService.interceptors.request.use(
  config => {
    // Get token from cookie if is browser and add to headers
    if (isBrowser) {
      const hasTokenHeader = config.headers.token
      if (hasTokenHeader) return config

      const access = getCookie('session')?.toString()
      if (access) config.headers.token = access
    }

    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

avivaUserService.interceptors.response.use(
  response => response,
  error => {
    interceptErrorHandler(error)

    return Promise.reject(error)
  }
)

avivaAppointmentService.interceptors.response.use(
  response => response,
  error => {
    interceptErrorHandler(error)

    return Promise.reject(error)
  }
)
export { alimServer, avivaAppointmentService, avivaUserService }
