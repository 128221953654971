import axios from 'axios'

import { API_URL } from './consts'

const baseURL = API_URL

const httpClient = axios.create({
  baseURL,
  timeout: 0,
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'es-ES',
    // Authorization: 'TOKEN',
    'Content-Type': 'application/json'

    // Config for CORS
    // 'Access-Control-Allow-Origin': '*'
  }
})

export default httpClient
