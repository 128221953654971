/* eslint-disable @typescript-eslint/indent */
import { avivaAppointmentService } from 'src/api/server'

import { getErrorMessage } from '@core/helpers/errors'
import { type AvivaServerResponse } from '@core/types/server/res'

import {
  type GetSaludProgramsAffiliatesParams,
  type GetSaludProgramsParams,
  type GetSaludProgramsPaymentScheduleParams,
  type ProgramPaymentSchedule,
  type ProgramsAffiliate,
  type SaludProgram,
  type SaludProgramAd,
  type SaludProgramCoverages
} from './types'

export const getSaludPrograms = async (params: GetSaludProgramsParams): Promise<SaludProgram[]> => {
  try {
    const { data } = await avivaAppointmentService.get<AvivaServerResponse<SaludProgram[]>>(
      '/arrangement/contracts',
      {
        params
      }
    )
    if (!data.data) {
      throw new Error('Codigo de error OK, sin embargo no vino la data (getSaludPrograms)')
    }

    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    throw new Error(message)
  }
}

export const getSaludProgramsAffiliates = async ({
  token,
  ...params
}: GetSaludProgramsAffiliatesParams): Promise<ProgramsAffiliate[]> => {
  try {
    const { data } = await avivaAppointmentService.get<AvivaServerResponse<ProgramsAffiliate[]>>(
      '/arrangement/contracts/affiliates',
      { params, headers: { token } }
    )
    if (!data.data) {
      throw new Error(
        'Codigo de error OK, sin embargo no vino la data (getSaludProgramsAffiliates)'
      )
    }

    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    throw new Error(message)
  }
}

export const getSaludProgramsPaymentSchedule = async ({
  token,
  ...params
}: GetSaludProgramsPaymentScheduleParams): Promise<ProgramPaymentSchedule[]> => {
  try {
    const { data } = await avivaAppointmentService.get<
      AvivaServerResponse<ProgramPaymentSchedule[]>
    >('/arrangement/payment/schedule', { params, headers: { token } })
    if (!data.data) {
      throw new Error(
        'Codigo de error OK, sin embargo no vino la data (GetSaludProgramsPaymentSchedule)'
      )
    }

    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    throw new Error(message)
  }
}

export const getSaludProgramsCoverages = async ({
  token,
  ...params
}: {
  contractId: string
  token: string
}): Promise<SaludProgramCoverages> => {
  try {
    const { data } = await avivaAppointmentService.get<AvivaServerResponse<SaludProgramCoverages>>(
      '/arrangement/coverages',
      { params, headers: { token } }
    )
    if (!data.data) {
      throw new Error('Codigo de error OK, sin embargo no vino la data (getSaludProgramsCoverages)')
    }

    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    throw new Error(message)
  }
}

export const getSaludProgramsAd = async (): Promise<SaludProgramAd[]> => {
  try {
    const { data } = await avivaAppointmentService.get<AvivaServerResponse<SaludProgramAd[]>>(
      '/arrangement/advertising'
    )
    if (!data.data) {
      throw new Error('No se obtuvieron las publicidad de los programas de salud')
    }

    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    throw new Error(message)
  }
}
