import { type IconProps } from '@core/types/components'

export const ShieldOutlinedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 32
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.44549 1.83348C9.76189 1.5503 10.2406 1.5503 10.557 1.83349C12.1793 3.28552 14.3197 4.16746 16.668 4.16746C16.8266 4.16746 16.9842 4.16344 17.1407 4.1555C17.5358 4.13546 17.8905 4.39596 17.9896 4.77895C18.215 5.64955 18.3346 6.56183 18.3346 7.50082C18.3346 12.5499 14.8813 16.7902 10.209 17.9928C10.0728 18.0279 9.92984 18.0279 9.79359 17.9928C5.12128 16.7902 1.66797 12.5499 1.66797 7.50082C1.66797 6.56179 1.78764 5.64948 2.013 4.77884C2.11213 4.39586 2.46686 4.13536 2.86196 4.1554C3.01844 4.16334 3.17603 4.16736 3.33464 4.16736C5.68289 4.16736 7.82319 3.28546 9.44549 1.83348ZM3.48585 5.83299C3.38658 6.37332 3.33464 6.93069 3.33464 7.50082C3.33464 11.6959 6.15333 15.2346 10.0013 16.3227C13.8493 15.2346 16.668 11.6959 16.668 7.50082C16.668 6.93073 16.616 6.37339 16.5168 5.83309C14.0616 5.79947 11.8023 4.9483 10.0012 3.54042C8.20015 4.94824 5.941 5.79937 3.48585 5.83299ZM13.0906 7.7449C13.416 8.07034 13.416 8.59798 13.0906 8.92341L9.75723 12.2567C9.43179 12.5822 8.90415 12.5822 8.57871 12.2567L6.91205 10.5901C6.58661 10.2646 6.58661 9.73701 6.91205 9.41157C7.23748 9.08613 7.76512 9.08613 8.09056 9.41157L9.16797 10.489L11.912 7.7449C12.2375 7.41946 12.7651 7.41946 13.0906 7.7449Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
