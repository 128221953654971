import type { IconProps } from '@core/types/components'

export function CloseIcon({ className = 'icon', size = 24, color = 'currentColor' }: IconProps) {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_233_20400)'>
          <path
            d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
            fill={color}
          />
        </g>
        <defs>
          <clipPath id='clip0_233_20400'>
            <rect width='24' height='24' fill={color} />
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}
