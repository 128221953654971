/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'

import { type MiniAlertConfigProps } from '@core/stores/miniAlert/useMiniAlertStore'

export const handleServerError = (
  error: any,
  responseType: 'normal' | 'full' = 'normal',
  openAlert?: (message: string, config?: MiniAlertConfigProps | undefined) => void
): void => {
  console.error(error)
  if (axios.isAxiosError(error)) {
    const errorMessage =
      responseType === 'normal'
        ? (error.response?.data.message as string)
        : (error.response?.data.result.message as string)

    openAlert?.(errorMessage)
    throw new Error(errorMessage)
  }
  openAlert?.(error.message as string)
  throw new Error(error.message as string)
}

export const getErrorMessage = (error: any, responseType: 'normal' | 'full' = 'normal'): string => {
  console.error(error)
  if (axios.isAxiosError(error)) {
    const errorMessage =
      responseType === 'normal'
        ? (error.response?.data.message as string)
        : (error.response?.data.result.message as string)

    return errorMessage
  }

  return error.message as string
}
