import { type IconProps } from '@core/types/components'

export const CheckboxCheckedIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='4' fill={color} />
        <path
          d='M10.125 17L4.5 11.375L5.38375 10.4913L10.125 15.2319L18.6163 6.74127L19.5 7.62502L10.125 17Z'
          fill='white'
        />
      </svg>
    </i>
  )
}
