import { type IconProps } from '@core/types/components'

export const ReceiptTaxIcon = ({
  className,
  color = 'var(--color-primary)',
  size = 24
}: IconProps) => {
  return (
    <i className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.9998 3.2002C6.23249 3.2002 4.7998 4.63288 4.7998 6.4002V28.8002L10.3998 25.6002L15.9998 28.8002L21.5998 25.6002L27.1998 28.8002V6.4002C27.1998 4.63288 25.7671 3.2002 23.9998 3.2002H7.9998ZM11.9998 8.0002C10.6743 8.0002 9.5998 9.07471 9.5998 10.4002C9.5998 11.7257 10.6743 12.8002 11.9998 12.8002C13.3253 12.8002 14.3998 11.7257 14.3998 10.4002C14.3998 9.07471 13.3253 8.0002 11.9998 8.0002ZM21.9312 8.46882C21.3063 7.84399 20.2933 7.84399 19.6684 8.46882L10.0684 18.0688C9.44359 18.6937 9.44359 19.7067 10.0684 20.3316C10.6933 20.9564 11.7063 20.9564 12.3312 20.3316L21.9312 10.7316C22.556 10.1067 22.556 9.09366 21.9312 8.46882ZM19.9998 16.0002C18.6743 16.0002 17.5998 17.0747 17.5998 18.4002C17.5998 19.7257 18.6743 20.8002 19.9998 20.8002C21.3253 20.8002 22.3998 19.7257 22.3998 18.4002C22.3998 17.0747 21.3253 16.0002 19.9998 16.0002Z'
          fill={color}
        />
      </svg>
    </i>
  )
}
