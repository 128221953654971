import { SuccessIcon2, WarningIcon2 } from '@core/components/icons'

import { type InputState } from './types'

export interface InputColor {
  normal: string
  hover: string
}

export const getInputColor = (state: InputState): InputColor => {
  if (state === 'error') {
    return { normal: 'var(--color-error)', hover: 'var(--color-error)' }
  }
  if (state === 'warning') {
    return { normal: 'var(--color-warning)', hover: 'var(--color-warning)' }
  }
  if (state === 'success') {
    return { normal: 'var(--color-success)', hover: 'var(--color-success)' }
  }

  return { normal: 'var(--color-neutral-gray-05)', hover: 'var(--color-primary)' }
}

export const getHelperTextIcon = (state: InputState, color: string, helperIcon?: JSX.Element) => {
  return state === 'error' ? (
    <WarningIcon2 size={13} color={color} />
  ) : state === 'success' ? (
    <SuccessIcon2 size={13} color={color} />
  ) : state === 'warning' ? (
    <WarningIcon2 size={13} color={color} />
  ) : (
    helperIcon
  )
}
