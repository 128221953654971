import { create } from 'zustand'

import { type SaludProgram, type SaludProgramAd } from '@services/programs/types'

interface ProgramPaySummary {
  programName: string
  numbersOfQuotas: string
  day: string
  hour: string
  amount: string
}

type State = {
  programs: SaludProgram[]
  programsAd: SaludProgramAd[] | null
  isLoading: boolean
  programPaySummary: ProgramPaySummary | null
}

const defaultValues: State = {
  programs: [],
  programsAd: null,
  isLoading: false,
  programPaySummary: null
}

type Actions = {
  setPrograms: (programs: SaludProgram[]) => void
  setProgramsAd: (programs: SaludProgramAd[] | null) => void
  setIsLoading: (value: boolean) => void
  setProgramPaySummary: (programPaySummary: ProgramPaySummary) => void
}

const useProgramsStore = create<State & Actions>(set => ({
  ...defaultValues,

  setPrograms: programs => set(() => ({ programs })),
  setProgramsAd: programsAd => set(() => ({ programsAd })),
  setIsLoading: isLoading => set(() => ({ isLoading })),
  setProgramPaySummary: programPaySummary => set(() => ({ programPaySummary }))
}))

export default useProgramsStore
