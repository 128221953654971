import { create } from 'zustand'

import { type Catalog, type Specialty } from '@core/types/components'
import { type Doctor } from '@services/appointmens/types'

export type SelectOption = { name: string; id: number | null }

export interface Center {
  id: number
  zipCode: string
  name: string
  phone: string | null
  address: string
  province: string | null
  district: string | null
  web: string | null
}

export type CatalogState = {
  consultTypeCatalog: Catalog[] | null
  doctorsCatalog: Doctor[] | null
  centersCatalog: Center[] | null
  specialtiesCatalog: Specialty[] | null
  documentTypesCatalog: Catalog[] | null
  sexsCatalog: Catalog[] | null
  relationsCatalog: Catalog[] | null
  guarantorsCatalog: Catalog[] | null
}

type Actions = {
  setConsultTypeCatalog: (consultTypes: Catalog[] | null) => void
  setDoctorsCatalog: (doctors: Doctor[] | null) => void
  setCentersCatalog: (centers: Center[]) => void
  setSpecialtiesCatalog: (specialties: Specialty[] | null) => void
  setSexsCatalog: (sexs: Catalog[] | null) => void
  setDocumentTypesCatalog: (documentTypes: Catalog[] | null) => void
  setRelationsCatalog: (relations: Catalog[] | null) => void
  setGuarantorsCatalog: (relations: Catalog[] | null) => void
}

const defaultValues: CatalogState = {
  consultTypeCatalog: null,
  doctorsCatalog: null,
  centersCatalog: null,
  specialtiesCatalog: null,
  documentTypesCatalog: null,
  relationsCatalog: null,
  sexsCatalog: null,
  guarantorsCatalog: null
}

const useCatalogStore = create<CatalogState & Actions>(set => ({
  ...defaultValues,

  setGuarantorsCatalog: guarantors => set(() => ({ guarantorsCatalog: guarantors })),
  setConsultTypeCatalog: consultTypes => set(() => ({ consultTypeCatalog: consultTypes })),
  setDoctorsCatalog: doctors => set(() => ({ doctorsCatalog: doctors })),
  setCentersCatalog: centers => set(() => ({ centersCatalog: centers })),
  setSpecialtiesCatalog: specialties => set(() => ({ specialtiesCatalog: specialties })),
  setSexsCatalog: sexsCatalog => set(() => ({ sexsCatalog })),
  setDocumentTypesCatalog: documentTypesCatalog => set(() => ({ documentTypesCatalog })),
  setRelationsCatalog: relationsCatalog => set(() => ({ relationsCatalog }))
}))
export default useCatalogStore
