import { colors } from '@core/helpers'
import type { IconProps } from '@core/types/components'

interface Props extends IconProps {
  isSelected?: boolean
  disabled?: boolean
  small?: boolean
}
export function CheckmarkIcon({
  disabled,
  className = 'icon',
  size = 24,
  isSelected = false,
  small
}: Props) {
  return (
    <i style={{ width: size, height: size, cursor: 'pointer' }} className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          width={typeof size === 'string' ? parseInt(size, 10) - 2 : size - 2}
          height={typeof size === 'string' ? parseInt(size, 10) - 2 : size - 2}
          x='1'
          y='1'
          rx='4'
          fill={isSelected ? colors.primary : 'white'}
          stroke={disabled ? 'var(--color-neutral-gray-06' : colors.primary}
          strokeWidth='2'
        />
        <path
          d={
            small
              ? 'M8.125 15L2.5 9.37496L3.38375 8.49121L8.125 13.2318L16.6163 4.74121L17.5 5.62496L8.125 15Z'
              : 'M10.125 17L4.5 11.375L5.38375 10.4912L10.125 15.2318L18.6163 6.74121L19.5 7.62496L10.125 17Z'
          }
          fill={isSelected ? 'white' : 'transparent'}
        />
      </svg>
    </i>
  )
}
